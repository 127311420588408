import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { Accordion, Button, Checkbox, Dimmer, Form, Grid, Header, Icon, Input, Loader, Modal } from 'semantic-ui-react';
import * as global_constants from '../../../global_constants';
import { search_place } from '../../../shared/action/autosearch_action';
import { advanced_search_class, get_contracts, get_insurance_types } from '../../../shared/action/shared_action';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import Selection from '../../../shared/component/selection_component';
import * as session_storage from '../../../shared/session_storage_utility';
import { handle_click_on_enter, set_focus_on_element_with_id, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { check_valid_npi, get_lauch_darkley_key_value, is_null_or_white_space, is_user_partner_customer_and_company_revEquip_revServe } from '../../../shared/utility';
import * as constants_actions from '../action/constants_action';
import * as constants from '../constants';
import { insurance_name, is_alpha_numeric, location_name } from '../utility';
import ProviderBillingHeaderComponent from './provider_billing_header_component';
import DrawerComponent from '../../../shared/component/drawer_component';
import { Position, Drawer } from '@blueprintjs/core';
import ProviderBillingAddEditComponent from './provider_billing_add_edit_component';
import ProviderClaimAddEditComponent from './provider_claim_add_edit_component';
import ProviderBillingDisplayRowComponent from './provider_billing_display_row_component';
import * as billingPaneConstants from '../provider_billing_pane_constants';
import { get_insurance_codes, get_provider_pending_reason } from '../action/constants_action';
import { ProviderBillingValidator } from './providerBilling/provider_billing_validator';
import { ConcreteStrategyClaimHoldNameUnique, ConcreteStrategySettingNameUnique } from './providerBilling/provider_billing_concrete_setting_name_unique';
import { ConcreteStrategyDataInGroups, ConcreteStrategySingleDataInGroups, ConcreteStrategyOnlyLocationInGroups } from './providerBilling/provider_billing_concrete_data_in_groups';
import { ConcreteStrategyDataRestrictionSpecific } from './providerBilling/provider_billing_concrete_data_restriction_specific';
import { ConcreteStrategyDataRestrictionAll } from './providerBilling/provider_billing_concrete_data_restriction_all';
import { ConcreteCombinationsLimit } from './providerBilling/provider_billing_concrete_combinations_limit';
import * as local_storage from '../../../shared/local_storage_utility';
import { DISABLED } from '@blueprintjs/core/lib/esm/common/classes';
import { withRouter } from 'react-router-dom'

class ProviderAddEditComponent extends React.Component<any, any> {
    _is_mounted = false;
    rowNodes: any;
    rowNodesForClaims: any;
    billing_selectedItems: any;
    claim_hold_selectedItems: any;
    validator: any = new ProviderBillingValidator();
    // API Promises
    getProviderDataPromise: Promise<string>;
    getProviderCodePromise: Promise<string>;
    getPrimarySpecialityPromise: Promise<string>;
    getProviderBillingListPromise: Promise<string>;
    getProviderClaimsListPromise: Promise<string>;
    retrieveLocationsAndInsurancesPromise: Promise<string>;
    retrievePendingReasonPromise: Promise<string>;
    // Resolved Promises
    getProviderDataPromiseResolve: any;
    getProviderCodePromiseResolve: any;
    getPrimarySpecialityPromiseResolve: any;
    getProviderBillingListPromiseResolve: any;
    getProviderClaimsListPromiseResolve: any;
    retrieveLocationsAndInsurancesPromiseResolve: any;
    retrievePendingReasonPromiseResolve: any;
    // Rejected Promises
    getProviderDataPromiseReject: any;
    getProviderCodePromiseReject: any;
    getPrimarySpecialityPromiseReject: any;
    getProviderBillingListPromiseReject: any;
    getProviderClaimsListPromiseReject: any;
    retrieveLocationsAndInsurancesPromiseReject: any;
    retrievePendingReasonPromiseReject: any;
    // API Collector to Track
    apiPromisesCollector: Promise<string>[] = [];
    current_user_type: any;
    company_service_type: any;
    is_new_claim_button_disable: boolean = false;
    incomingPayload: any;

    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            patient_master_data: this.props.user_login_details.formated_master_data,
            show_render: true,
            form_object: {},
            is_loading: true,
            is_submitted: false,
            is_save_button_disabled: false,
            isSaveInProgress: false,
            required_error: {},
            error: {},
            required_filed: {},
            is_error_on_change: {},
            primary_specialty: [],
            provider_types: [],
            provider_qualifier: [],
            location_search: [],
            insurance_class: [],
            locations: [],
            insuranceClasses: [],
            insuranceCodes: [],
            insurance_types: [],
            insurance_contract: [],
            billing_rows: [],
            billing_rows_original: [],
            billing_col_fields: this.retrieveBillingProviderSettingsColumns(),
            unsave_billing_rows: [],
            claim_hold_rows: [],
            claim_hold_rows_original: [],
            claim_hold_col_fields: this.retrieveProviderClaimHoldsColumns(),
            unsaved_claim_rows: [],
            claim_delay_reason: [],
            isDrawerOpen: false,
            isClaimDrawerOpen: false,
            show_inactive_billing: false,
            show_inactive_claim: false,
            controls_is_expended: {
                provider: true,
                claim: true,
                billing: true
            },
            providerBilling: {
                originalRowData: {},
                originalRowIndex: {}
            },
            providerClaimHold: {
                originalRowData: {},
                originalRowIndex: {}
            },
            openPanelMode: {
                isModeEditable: false,
                isModeCreate: false
            },
            claimTabId: '',
            billingTabId: '',
            confirm_box_open: false
        };
        this.rowNodes = [];
        this.rowNodesForClaims = [];
        this.billing_selectedItems = [];
        this.claim_hold_selectedItems = [];
    }

    retrieveProviderClaimHoldsColumns = () => {
        return billingPaneConstants.provider_claim_holds_columns;
    }

    retrieveBillingProviderSettingsColumns = () => {
        return billingPaneConstants.gridColumns;
    }

    // Define initial properties and its value.
    form_object: any = {
        first_name: '',
        last_name: '',
        description: '',
        provider_type: '',
        provider_type_id: null,
        primary_specialty: '',
        primary_specialty_id: null,
        taxonomy: '',
        national_provider_identifier: '',
        state_license_number: '',
        alt_record_id: '',
        provider_code: '',
        provider_id: 0,
        status: true,
        hasChanges: false
    };

    required_filed: any = {
        first_name: true,
        last_name: true,
        description: true,
        provider_type_id: true,
        primary_specialty_id: true,
        provider_code: true
    };

    page_name: string = 'provider';
    page_route: string = '';
    unlisten: any;
    page_metadata: any;
    token: string = '';
    company_id: Number = 0;
    company_details: any = {};
    code_id: 0;
    is_grid_visible = false;
    initial_form_object: any = {};
    go_next: boolean = false;
    is_cancel: boolean = false;
    provider_search_criteria: any = {
        provider_code: '',
        first_name: '',
        last_name: '',
        show_inactive: false
    };
    menu_permission_object: Array<any> = [{ update: true }];
    disable_fields = false;

    //Billing data variable
    billing_model: any = {
        provider_billing_id: 0,
        setting_id:0,
        provider_id: 0,
        location_id: '',
        insurance_class: '',
        insurance_class_id: 0,
        insurance_code: '',
        insurance_code_id: '',
        individual_number: '',
        individual_qualifier: '',
        group_number: '',
        group_qualifier: '',
        tax_identification_number: '',
        ssn_tin_flag: true,
        status: true,
        hasChanges: false,
        setting_name: '',
        location_ids: [],
        insurance_class_ids: [],
        insurance_code_ids: []
    };

    //Billing data variable
    claim_model: any = {
        claim_hold_name: '',
        claim_delay_reason_id: '31',
        start_date: null,
        end_date: null,
        claim_hold_id: 0,
        provider_id: 0,
        location_ids: [],
        insurance_class: '',
        insurance_class_ids: [],
        insurance_code: '',
        insurance_code_ids: [],
        not_used_for_billing: false,
        status: true,
        hasChanges: false
    };

    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        const { user_data } = this.props.user_login_details;
        this.token = user_data.data.token_details.access_token;
        this.company_id = user_data.data.company_id;
        this.code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
        this.get_page_metadata(this.props.location, null);
        //Verifying page_name is correct or not.
        this.initial_form_object = this.init_fields(this.form_object);
        this.generateFirstCallsPromises();
        const auth_data = local_storage.get("auth_data");
        if (auth_data && auth_data.user_type_r3_id) {
            this.current_user_type = auth_data.user_type_r3_id;
            this.company_service_type = auth_data.company_service_type;
        }

        if (
            is_user_partner_customer_and_company_revEquip_revServe(this.company_service_type, this.current_user_type)
        ) {
            this.is_new_claim_button_disable = true;
        }
        this.unlisten = this.props.history.listen((location, action) => {
            this.get_page_metadata(location, action);
        });
    };

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        this.init_data(this.props.user_login_details.master_data);
        all_form_elem[0].focus();
        if (this.props.features && this.props.features.isProviderClaimsPaneEnabled) {
            this.retrievePendingReasons();
        }
        if (this.code_id) {
            this.checkUpdatePermission();
            this.get_data(this.code_id);
        } else {
            this.setState(state => ({
                form_object: this.init_fields(this.form_object),
            }));
            this.get_provider_code();
        }

        this.get_primary_speciality();
        this.get_api_data();
        if (this.code_id > 0) {
            this.get_provider_billing_list(this.code_id);
            this.get_provider_claim_list(this.code_id);
        }
        this.retrieveLocationsAndInsurancesForMultiTab();
        Promise.all(this.apiPromisesCollector).then(() => {
            this.setState({
                is_loading: false
            });
        });
    };

    componentWillUnmount = () => {
        this.unlisten();
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[1] !== this.page_metadata.route && session_storage.remove(this.page_metadata.storage_key);
        }
        session_storage.remove('provider_data_loaded');
        session_storage.remove('provider_billing_data_loaded');
        if (this.props.features && this.props.features.isProviderClaimsPaneEnabled) {
            session_storage.remove('provider_claim_data_loaded');
        }
    };

    componentDidUpdate(previousProps, previousState) {

        /**
         * Handling the scenario when response from launch darkly is slow.
         * Retrieving locations, insurances if the status of LD flag is true
         * and previously it was false.
         *  */
        if (
            this.props.features &&
            previousProps.features &&
            this.props.features.isProviderClaimsPaneEnabled &&
            !previousProps.features.isProviderClaimsPaneEnabled
        ) {
            this.retrieveLocationsAndInsurancesForMultiTab();
        }
    }

    get_page_metadata = (location, action) => {
        let parts = location.pathname.split('/');
        this.page_route = parts[1];
        switch (this.page_route) {
            case 'admin':
                this.page_metadata = constants.admin_provider;
                break;
            case 'billing':
                this.page_metadata = constants.billing_provider;
                break;
        }
    }

    generateFirstCallsPromises = () => {
        this.getProviderDataPromise = new Promise((resolve, reject) => {
            this.getProviderDataPromiseResolve = resolve;
            this.getProviderDataPromiseReject = reject;
        });
        this.getProviderCodePromise = new Promise((resolve, reject) => {
            this.getProviderCodePromiseResolve = resolve;
            this.getProviderCodePromiseReject = reject;
        });
        this.getPrimarySpecialityPromise = new Promise((resolve, reject) => {
            this.getPrimarySpecialityPromiseResolve = resolve;
            this.getPrimarySpecialityPromiseReject = reject;
        });
        this.getProviderBillingListPromise = new Promise((resolve, reject) => {
            this.getProviderBillingListPromiseResolve = resolve;
            this.getProviderBillingListPromiseReject = reject;
        });
        this.getProviderClaimsListPromise = new Promise((resolve, reject) => {
            this.getProviderClaimsListPromiseResolve = resolve;
            this.getProviderClaimsListPromiseReject = reject;
        });
        this.retrieveLocationsAndInsurancesPromise = new Promise((resolve, reject) => {
            this.retrieveLocationsAndInsurancesPromiseResolve = resolve;
            this.retrieveLocationsAndInsurancesPromiseReject = reject;
        });
        this.retrievePendingReasonPromise = new Promise((resolve, reject) => {
            this.retrievePendingReasonPromiseResolve = resolve;
            this.retrievePendingReasonPromiseReject = reject;
        });
    };

    checkUpdatePermission = () => {
        this.menu_permission_object = this.props.shared_details.left_menu_data.data
            .filter((menu) => {
                return menu.name == 'Edit Providers';
            });
        
        if (this.menu_permission_object.length == 0 || !this.menu_permission_object[0].view) {
            this.disable_fields = true;
            this.setState({ is_save_button_disabled: true })
        }
    }

    init_data = (master_data) => {
        const state_object = {};
        if (master_data && master_data.data && master_data.data) {
            if (master_data.data.provider_types) {
                state_object['provider_types'] = master_data.data.provider_types.map((val, index) => { return { key: index + 1, value: val.id, text: val.name, data: val } })
            }
            if (master_data.data.provider_qualifier) {
                state_object['provider_qualifier'] = master_data.data.provider_qualifier.map((val, index) => { return { key: index + 1, value: val.type_code, text: val.type_code, data: val } })
                state_object['provider_qualifier'].unshift({ key: -1, value: ' ', text: 'NONE', data: { value: ' ', text: 'NONE' } });
            }
            this.setState(state_object);
        }
    }

    init_fields = (value) => {
        const object = { ...value };
        object.national_provider_identifier = value.national_provider_identifier || '';
        object.taxonomy = value.taxonomy || '';
        object.state_license_number = value.state_license_number || '';
        object.alt_record_id = value.alt_record_id || '';
        return object;
    }

    on_unsave_billing = (row_num, is_unsave) => {
        if (is_unsave && this.state.unsave_billing_rows.indexOf(row_num) == -1) {
            this.setState(state => ({
                unsave_billing_rows: [...state.unsave_billing_rows, row_num]
            }))
        } else if (!is_unsave && this.state.unsave_billing_rows.indexOf(row_num) != -1) {
            this.setState(state => ({
                unsave_billing_rows: state.unsave_billing_rows.filter(val => val !== row_num)
            }))
        }
    }

    on_unsaved_claims = (row_num, is_unsave) => {
        if (is_unsave && this.state.unsaved_claim_rows.indexOf(row_num) == -1) {
            this.setState(state => ({
                unsaved_claim_rows: [...state.unsaved_claim_rows, row_num]
            }))
        } else if (!is_unsave && this.state.unsaved_claim_rows.indexOf(row_num) != -1) {
            this.setState(state => ({
                unsaved_claim_rows: state.unsaved_claim_rows.filter(val => val !== row_num)
            }))
        }
    }

    /*Dropdown API call*/
    get_api_data = async () => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        const params = { page_size: global_constants.constants.PAGE_SIZE };
        const location_search = await search_place(params, token).then(res => res.data);
        const insurance_class = await advanced_search_class(params, token).then(res => res.data);
        let data_list = [];
        if (location_search && location_search.data && location_search.data.result) {
            data_list = location_search.data.result.map((val, index) => { return { key: index, value: val.id, text: location_name(val), data: val } })
        }
        let insurance_list = [];
        if (insurance_class && insurance_class.data && insurance_class.data) {
            insurance_class.data= insurance_class.data.sort(function (obj1, obj2) {
                var nameA = obj1.insurance_class_description, nameB = obj2.insurance_class_description;
                if (nameA < nameB) //sort string ascending
                    return -1
                if (nameA > nameB)
                    return 1
                return 0
            })

            insurance_list = insurance_class.data.map((val, index) => { return { key: index, value: val.id, text: insurance_name(val), data: val } })
        }

        let available_contracts = await get_contracts(token).then(res => {
            if (res.data.data != null && res.data.data.length > 0) {
                return res.data.data;
            }
        });

        let ins_type = await get_insurance_types(token).then(res => {
            if (res.data.data != null && res.data.data.length > 0) {
                return res.data.data.map((val, index) => {
                    return { key: index, value: val.id, text: val.name }
                });
            }
        });
        // let claim_delay_reason = []
        // if(this.props.features && this.props.features.isProviderClaimsPaneEnabled){
        //     claim_delay_reason = await get_provider_pending_reason(token).then(res => {
        //         if (res.data.data != null && res.data.data.length > 0) {
        //             return res.data.data.map((val, index) => {
        //                 return { key: index, value: val.pending_reason_id, text: val.pending_reason_desc }
        //             });
        //         }
        //     });
        //     claim_delay_reason.reverse();
        // }
        this.setState({
            location_search: data_list,
            insurance_class: insurance_list,
            insurance_types: ins_type,
            insurance_contract: available_contracts,
            // claim_delay_reason
        });
    }

    get_provider_code = () => {
        const url = `${global_constants.end_points.provider_type_url.provider_code}`;

        this.apiPromisesCollector.push(this.getProviderCodePromise);
        constants_actions.get_data(this.token, url).then(
            response => {
                const result = {};
                if (response.data && response.data.data) {
                    this.form_object['provider_code'] = response.data.data;
                    this.initial_form_object = { ...this.form_object };
                    result['form_object'] = this.form_object;
                }
                this.setState(result);
                this.getProviderCodePromiseResolve('ok');
            },
            error => {
                // TODO : do something here
                // if (error.response.data) console.log(error.response.data.messages[0].message);
                this.getProviderCodePromiseReject('error');
            }
        );
    }

    get_primary_speciality = () => {
        const url = `${global_constants.end_points.provider_type_url.primary_speciality}`;

        this.apiPromisesCollector.push(this.getPrimarySpecialityPromise);
        constants_actions.get_data(this.token, url).then(
            response => {
                if (response.data && response.data.data) {
                    const state_object = {};
                    state_object['primary_specialty'] = response.data.data.map((val, index) => { return { key: index, value: val.specialty_id, text: val.specialty_desc, data: val } })
                    this.setState(state_object);
                }
                this.getPrimarySpecialityPromiseResolve('ok');
            },
            error => {
                // TODO : do something here
                // if (error.response.data) console.log(error.response.data.messages[0].message);
                this.getPrimarySpecialityPromiseReject('error');
            }
        );
    }

    // Get the data on the basis of ID.
    get_data = code_id => {
        let url = this.page_metadata.api.get.url;
        url = url.replace('id_value', code_id);

        this.apiPromisesCollector.push(this.getProviderDataPromise);
        const providerDataInStorage = session_storage.get('provider_data_loaded');

        if (providerDataInStorage) {
            this.form_object = this.init_fields(providerDataInStorage);
            this.initial_form_object = { ...this.form_object };
            this.setState({
                form_object: this.form_object
            });
            this.getProviderDataPromiseResolve('ok');
        } else {
            constants_actions.get_data(this.token, url).then(
                response => {
                    if (response.data && response.data.data) {
                        this.form_object = this.init_fields(response.data.data);
                        this.initial_form_object = { ...this.form_object };
                        this.setState({
                            form_object: this.form_object
                        });
                        this.getProviderDataPromiseResolve('ok');
                        session_storage.set('provider_data_loaded', response.data.data);
                    }
                },
                error => {
                    // TODO : do something here
                    //if (error.response.data) console.log(error.response.data.messages[0].message);
                    this.getProviderDataPromiseReject('error');
                }
            );
        }
    };

    get_provider_billing_list = (provider_id) => {
        this.apiPromisesCollector.push(this.getProviderBillingListPromise);
        const providerBillingDataInStorage = session_storage.get('provider_billing_data_loaded');
        if (providerBillingDataInStorage) {
            this.setState({
                billing_rows: providerBillingDataInStorage,
                billing_rows_original: [...providerBillingDataInStorage]
            })
            this.getProviderBillingListPromiseResolve('ok');
        } else {
            constants_actions.get_provider_billings(this.token, provider_id).then(
                response => {
                    response.data.data.map((item) => {
                        item.location_id = parseInt(item.location_id);
                        item.individual_qualifier = item.individual_qualifier ? item.individual_qualifier.trim() : item.individual_qualifier;
                        item.group_qualifier = item.group_qualifier ? item.group_qualifier.trim() : item.group_qualifier;
                        return item;
                    });
                    this.setState({
                        billing_rows: response.data.data,
                        billing_rows_original: [...response.data.data]
                    })
                    this.getProviderBillingListPromiseResolve('ok');
                    session_storage.set('provider_billing_data_loaded', response.data.data);
                }, (error) => {
                    this.getProviderBillingListPromiseReject('error');
                })
        }
    }

    get_provider_claim_list = (provider_id) => {
        this.apiPromisesCollector.push(this.getProviderClaimsListPromise);
        const providerClaimDataInStorage = session_storage.get('provider_claim_data_loaded');
        if (providerClaimDataInStorage) {
          this.setState({
            claim_hold_rows: providerClaimDataInStorage,
            claim_hold_rows_original: [...providerClaimDataInStorage]
          });
          this.getProviderClaimsListPromiseResolve('ok');
        } else {
          constants_actions.get_provider_claim_hold(this.token, provider_id).then(
              (response) => {
              response.data.data.map((item) => {
                item.location_id = parseInt(item.location_id);
                item.individual_qualifier = item.individual_qualifier
                  ? item.individual_qualifier.trim()
                  : item.individual_qualifier;
                item.group_qualifier = item.group_qualifier ? item.group_qualifier.trim() : item.group_qualifier;
                return item;
              });
              this.setState({
                claim_hold_rows: response.data.data,
                claim_hold_rows_original: [...response.data.data]
              });
              this.getProviderClaimsListPromiseResolve('ok');
              session_storage.set('provider_claim_data_loaded', response.data.data);
            },
            (error) => {
              this.getProviderClaimsListPromiseReject('error');
            }
          );
        }
    }

    private retrieveLocationsAndInsurancesForMultiTab = async () => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        const params = { page_size: global_constants.constants.PAGE_SIZE };

        this.apiPromisesCollector.push(this.retrieveLocationsAndInsurancesPromise);
        await get_insurance_codes(token)
            .then(response => {
                this.setState({
                    insuranceCodes: response.data.data
                });
            }, (error) => {
                this.retrieveLocationsAndInsurancesPromiseReject('error');
            })
            .then(async () => {
                await search_place(params, token).then(response => {
                    this.setState({
                        locations: response.data.data.result
                    });
                });
            }, (error) => {
                this.retrieveLocationsAndInsurancesPromiseReject('error');
            })
            .then(async () => {
                await advanced_search_class(params, token)
                    .then(response => {
                        this.setState({
                            insuranceClasses: response.data.data
                        });
                        this.retrieveLocationsAndInsurancesPromiseResolve('ok');
                    });
            }, (error) => {
                this.retrieveLocationsAndInsurancesPromiseReject('error');
            });
    }


    private retrievePendingReasons = async () => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;

        let claim_delay_reason = []
        
        claim_delay_reason = await get_provider_pending_reason(token).then(res => {
            if (res.data.data != null && res.data.data.length > 0) {
                return res.data.data.map((val, index) => {
                    return { key: index, value: val.pending_reason_id, text: val.pending_reason_desc }
                });
            }
        }, (error) => {
            this.retrievePendingReasonPromiseReject('error');
            return;
        });
        claim_delay_reason.reverse();
        this.setState({
            claim_delay_reason
        }, ()=> {
            this.retrievePendingReasonPromiseResolve('ok');
        })
    }


    //Input change handler.
    input_change_handler = e => {
        const { value, name } = e.target;
        let error = this.state.error;
        error[name] = this.validate_value(value, name);
        this.setState(prev_state => ({
            required_error: {
                ...prev_state.required_error,
                [name]: false
            },
            form_object: this.form_object
        }));
        session_storage.set('provider_data_loaded', this.form_object);
    };

    //Input checkbox handler.
    checkbox_handler = e => {
        const { checked, name } = e.target;
        this.form_object[name] = checked;
        this.setState(prev_state => ({
            required_error: {
                ...prev_state.required_error,
                [name]: false
            },
            form_object: this.form_object
        }));
        session_storage.set('provider_data_loaded', this.form_object);
    };

    //Dropdown change handler
    on_change_dropdown_handler = e => {
        const { value, name } = e.target;
        this.form_object[name] = value == ' ' ? null : Number(value);

        this.setState(prev_state => ({
            required_filed: {
                ...this.required_filed
            },
            required_error: {
                ...prev_state.required_error,
                [name]: false
            },
            form_object: this.form_object
        }));
        session_storage.set('provider_data_loaded', this.form_object);
    };


    // validate each input box on change event
    validate_value = (value, name) => {
        var error = false;
        switch (name) {
            case 'national_provider_identifier':
                if (!isNaN(value)) {
                    error = (value && value.trim().length > 0) ? !check_valid_npi(value) : false;
                    this.form_object[name] = value && value.trim()||'';
                }
                break;

            case 'taxonomy':
                if (is_alpha_numeric(value)) {
                    error = (value && value.trim().length > 0 && value.trim().length < 10) ? true : false;
                    this.form_object[name] = value && value.trim()||'';
                }
                break;
            case 'provider_code':
                if (is_alpha_numeric(value)) {
                    error = (value && value.trim().length > 0 && value.trim().length < 3 ) ? true : false;
                    this.form_object[name] = value && value.trim() || '';
                }
                break;
            default:
                this.form_object[name] = value;
                return false;
        }
        return error;
    };

    //Save the form object and redirect it to search
    save_handler = (e, from_pop_up) => {
        e.preventDefault();
        this.setState({
            is_submitted: true,
            isSaveInProgress: true
        });
        let is_error = false;
        this.go_next = false;
        let error = this.state.error;
        for (let name in this.form_object) {
            if (name !== 'status' && name !== 'primary_specialty_id'
                && name !== 'provider_id' && name !== 'provider_type_id'
                && this.form_object[name]) {
                this.form_object[name] = this.form_object[name].toString().trim();
            }
            if (this.required_filed[name] && !this.form_object[name]) {
                this.setState(prev_state => ({
                    required_error: {
                        ...prev_state.required_error,
                        [name]: true
                    }
                }));
                is_error = true;
            }
            error[name] = this.validate_value(this.form_object[name], name);
            if (!is_error) {
                is_error = error[name];
            }
        }

        this.setState(prev_state => ({
            error: {
                ...prev_state.error
            }
        }));

        if (is_error) {
            toaster.error('', constants.mandatory_fields);
            this.setState({
                isSaveInProgress: false
            });
            return (is_error = true);
        }

        // Check Fields Validation for "Provider Information" block
        for (let key in this.state.error) {
            if (this.state.error[key]) {
                toaster.error('', constants.mandatory_fields);
                is_error = true;
            }
        }

        // Check restriction data for "Billing Settings" block   
        this.validator.setStrategy(new ConcreteStrategyDataRestrictionAll());
        if (!this.validator.isValid(this.state.billing_rows)) {
            toaster.error('', billingPaneConstants.dataRestrictionValidationError);
            is_error = true;
        }
        this.validator.setStrategy(new ConcreteCombinationsLimit());
        const billingSettingsRows: any[] = [...this.state.billing_rows];
        let valuesOverLimit: boolean = false;

        if (billingSettingsRows.length > 0) {
            for (let iterator = 0; iterator < billingSettingsRows.length; iterator++) {
                if (!this.validator.isValid([billingSettingsRows[iterator], null])) {
                    valuesOverLimit = true;
                    break;
                }
            }

            if (valuesOverLimit) {
                toaster.error('', billingPaneConstants.maximumCombinationsExceeded);
                is_error = true;
            }
        }

        if (is_error) {
            this.setState({
                isSaveInProgress: false
            });
            return;
        }
        this.setState({
            is_loading: true,
            is_save_button_disabled: true
        });
        //session_storage.set(this.page_metadata.storage_key, this.provider_search_criteria);
        if (this.code_id) {
            //Update
            let url = this.page_metadata.api.update.url;
            url = url.replace('id_value', this.code_id);
            constants_actions.update_data(this.token, url, this.form_object).then(
                async response => {
                    if (response.data && response.data.data) {
                        this.initial_form_object = { ...this.form_object };

                        // writing a promise all 
                        let provider_billing_data = this.get_all_updated_data();
                        provider_billing_data.forEach(data => {
                          if (data) {
                              data.location_id = is_null_or_white_space(data.location_id) ? null : data.location_id;
                              data.insurance_code = is_null_or_white_space(data.insurance_code) ? null : data.insurance_code;
                              data.insurance_code_id = is_null_or_white_space(data.insurance_code_id) ? null : data.insurance_code_id;
                              data.insurance_class = is_null_or_white_space(data.insurance_class) ? null : data.insurance_class;
                              data.insurance_class_id = is_null_or_white_space(data.insurance_class_id) ? null : data.insurance_class_id;
                          }
                        });
                        let arrData = [];
                        if(provider_billing_data.length > 0) {
                            arrData.push(constants_actions.update_provider_billings(this.token, this.code_id, provider_billing_data));
                        }

                        let  provider_claim_data = this.get_all_updated_claim_data();
                        if (provider_claim_data.length > 0) {
                            arrData.push(constants_actions.update_provider_claim_hold(this.token, this.code_id, provider_claim_data));
                        }

                        if(arrData.length > 0) {
                            await Promise.all(arrData)
                            .then(
                              (response) => {
                                let providerBillingData = null, claimProviderData = null;
                                if(provider_billing_data.length > 0) {
                                    providerBillingData = response[0].data;
                                }
                                if (provider_billing_data.length > 0 && provider_claim_data.length > 0) {
                                    claimProviderData = response[1].data;
                                } else if(provider_claim_data.length > 0){
                                    claimProviderData = response[0].data;
                                }
                                
                                if (providerBillingData) {
                                    if (providerBillingData.status == 1) {
                                        this.go_next = true;
                                        toaster.success('', this.page_metadata.validation_message.update);
                                        this.setState({
                                            is_loading: false,
                                            is_save_button_disabled: false,
                                            isSaveInProgress: false,
                                            unsave_billing_rows: [],
                                            by_pass_prompt: !from_pop_up ? true : false
                                        });                              
                                    } else {
                                        this.show_toaster_error(providerBillingData.messages);
                                        this.go_next = false;
                                        if (this._is_mounted) {
                                            this.setState({
                                                is_loading: false,
                                                is_save_button_disabled: false,
                                                isSaveInProgress: false
                                            });
                                        }
                                    }
                                }
                                
                                if (claimProviderData) {
                                    if (claimProviderData.status == 1) {
                                        this.go_next = true;
                                        toaster.success('', this.page_metadata.validation_message.update);
                                        this.setState({
                                            is_loading: false,
                                            is_save_button_disabled: false,
                                            isSaveInProgress: false,
                                            unsaved_claim_rows: [],
                                            by_pass_prompt: !from_pop_up ? true : false
                                        });                              
                                    } else {
                                        this.show_toaster_error(claimProviderData.messages);
                                        this.go_next = false;
                                        if (this._is_mounted) {
                                            this.setState({
                                                is_loading: false,
                                                is_save_button_disabled: false,
                                                isSaveInProgress: false
                                            });
                                        }
                                    }    
                                }
    
                                if (!from_pop_up && this.go_next) {
                                    this.go_next = false;
                                    let last_report_navigation = local_storage.get('last_report_navigation');
                                    local_storage.remove('last_report_navigation');
                                    if (!last_report_navigation) {
                                        this.props.history.goBack()
                                    } else {
                                        this.props.history.push(last_report_navigation);
                                    }
                                }
                              }
                            )
                            .catch((error) => {
                                if (this._is_mounted) {
                                    this.go_next = false;
                                    this.setState({
                                        is_loading: false,
                                        is_save_button_disabled: false,
                                        isSaveInProgress: false
                                    });
                                }
                                if (error.response.data) this.show_toaster_error(error.response.data.messages);
                            });
    
                        } else {
                            this.go_next = true;
                            toaster.success('', this.page_metadata.validation_message.update);
                            if (!from_pop_up && this.go_next) {
                                this.go_next = false;
                                let last_report_navigation = local_storage.get('last_report_navigation');
                                local_storage.remove('last_report_navigation');
                                if (!last_report_navigation) {
                                    this.props.history.goBack()
                                } else {
                                    this.props.history.push(last_report_navigation);
                                }
                            }
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false,
                                isSaveInProgress: false
                            });
                        }                        
                    } else {
                        this.show_toaster_error(response.data.messages);
                        this.go_next = false;
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false,
                                isSaveInProgress: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false,
                            isSaveInProgress: false
                        });
                    }
                    if (error.response.data) this.show_toaster_error(error.response.data.messages);
                }
            );
        } else {
            //Add
            let url = this.page_metadata.api.add.url;
            constants_actions.add_data(this.token, url, this.form_object).then(
                async response => {
                    if (response.data && response.data.data) {
                        this.initial_form_object = { ...this.form_object };

                          // writing a promise all 
                          let provider_billing_data = this.get_all_updated_data();
                          provider_billing_data.forEach(data => {
                            if (data) {
                                data.location_id = is_null_or_white_space(data.location_id) ? null : data.location_id;
                                data.insurance_code = is_null_or_white_space(data.insurance_code) ? null : data.insurance_code;
                                data.insurance_code_id = is_null_or_white_space(data.insurance_code_id) ? null : data.insurance_code_id;
                                data.insurance_class = is_null_or_white_space(data.insurance_class) ? null : data.insurance_class;
                                data.insurance_class_id = is_null_or_white_space(data.insurance_class_id) ? null : data.insurance_class_id;
                            }
                          });
                          let arrData = [];
                          if(provider_billing_data.length > 0) {
                              arrData.push(constants_actions.add_provider_billings(this.token, response.data.data, provider_billing_data));
                          }
  
                          let  provider_claim_data = this.get_all_updated_claim_data();
                          if (provider_claim_data.length > 0) {
                              arrData.push(constants_actions.add_provider_claim_hold(this.token, response.data.data, provider_claim_data));
                          }
  
                          if(arrData.length > 0) {
                            await Promise.all(arrData)
                            .then(
                              (response) => {
                                let providerBillingData = null, claimProviderData = null;
                                if(provider_billing_data.length > 0) {
                                    providerBillingData = response[0].data;
                                }
                                if (provider_billing_data.length > 0 && provider_claim_data.length > 0) {
                                    claimProviderData = response[1].data;
                                } else if(provider_claim_data.length > 0){
                                    claimProviderData = response[0].data;
                                }
                                
                                if (providerBillingData) {
                                    if (providerBillingData.status == 1) {
                                        this.go_next = true;
                                        toaster.success('', this.page_metadata.validation_message.save);   
                                        this.setState({
                                            is_loading: false,
                                            is_save_button_disabled: false,
                                            isSaveInProgress: false,
                                            by_pass_prompt: !from_pop_up ? true : false,
                                            unsave_billing_rows: []
                                        });                             
                                    } else {
                                        this.show_toaster_error(providerBillingData.messages);
                                        this.go_next = false;
                                        if (this._is_mounted) {
                                            this.setState({
                                                is_loading: false,
                                                is_save_button_disabled: false,
                                                isSaveInProgress: false
                                            });
                                        }
                                    }
                                }
                                
                                if (claimProviderData) {
                                    if (claimProviderData.status == 1) {
                                        this.go_next = true;
                                        toaster.success('', this.page_metadata.validation_message.save);   
                                        this.setState({
                                            is_loading: false,
                                            is_save_button_disabled: false,
                                            isSaveInProgress: false,
                                            by_pass_prompt: !from_pop_up ? true : false,
                                            unsaved_claim_rows: []
                                        });                          
                                    } else {
                                        this.show_toaster_error(claimProviderData.messages);
                                        this.go_next = false;
                                        if (this._is_mounted) {
                                            this.setState({
                                                is_loading: false,
                                                is_save_button_disabled: false,
                                                isSaveInProgress: false
                                            });
                                        }
                                    }    
                                }
    
                                if (!from_pop_up && this.go_next) {
                                    this.go_next = false;
                                    let last_report_navigation = local_storage.get('last_report_navigation');
                                    local_storage.remove('last_report_navigation');
                                    if (!last_report_navigation) {
                                        this.props.history.goBack()
                                    } else {
                                        this.props.history.push(last_report_navigation);
                                    }
                                }
                              }
                            )
                            .catch((error) => {
                                if (this._is_mounted) {
                                    this.go_next = false;
                                    this.setState({
                                        is_loading: false,
                                        is_save_button_disabled: false,
                                        isSaveInProgress: false
                                    });
                                }
                                if (error.response.data) this.show_toaster_error(error.response.data.messages);
                            });
    
                        } else {
                            this.go_next = true;
                            toaster.success('', this.page_metadata.validation_message.save);
                            if (!from_pop_up && this.go_next) {
                                this.go_next = false;
                                let last_report_navigation = local_storage.get('last_report_navigation');
                                local_storage.remove('last_report_navigation');
                                if (!last_report_navigation) {
                                    this.props.history.goBack()
                                } else {
                                    this.props.history.push(last_report_navigation);
                                }
                            }
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false,
                                isSaveInProgress: false
                            });
                        }                        

                    } else {
                        this.show_toaster_error(response.data.messages);
                        this.go_next = false;
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false,
                                isSaveInProgress: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false,
                            isSaveInProgress: false
                        });
                    }
                    if (error.response.data) this.show_toaster_error(error.response.data.messages);
                }
            );
        }
    };

    show_toaster_error = (messages) => {
        if (messages && messages.length > 0) {
            var toaster_message = "";
            messages.forEach((element) => {
                if (toaster_message != "")
                    toaster_message = toaster_message +"<br>" + element.message;
                else 
                    toaster_message = element.message;
            });
            if (toaster_message.indexOf('<br') > -1) {
                var toastr_options = this.show_html_content_toaster(toaster_message);
                toaster.error('', toastr_options);
            } else {
                toaster.error('', toaster_message);
            }

        }
    }

    //Function used to Run Report based on controllers values.
    cancel_handler = () => {
        let last_report_navigation = local_storage.get('last_report_navigation');
        local_storage.remove('last_report_navigation');
        if (!last_report_navigation) {
            let last_report_navigation = local_storage.get('last_report_navigation');
            local_storage.remove('last_report_navigation');
            if (!last_report_navigation) {
                this.props.history.goBack()
            } else {
                this.props.history.push(last_report_navigation);
            }
        } else {
            this.props.history.push(last_report_navigation);
        }
        
    };

    // Show multiple messages
    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    render_billing_rows = () => {
      if (this.state.billing_rows && this.state.billing_rows.length > 0) {
        return this.state.billing_rows.map((item, index) => {
          if (this.billing_selectedItems[index] == undefined) {
            this.billing_selectedItems[index] = {
              locations: item.location_ids,
              insuranceClass: item.insurance_class_ids,
              insuranceCode: item.insurance_code_ids
            };
          }
          return this.state.show_inactive_billing ? (
            <ProviderBillingDisplayRowComponent
              key={index}
              data={item}
              onClickCallbackHandler={this.handleProviderBillingRowClick}
              onClickBadgeItemHandler={this.onClickBadgeItemHandler}
              column_fields={this.state.billing_col_fields}
              row_index={index}
              locations={this.billing_selectedItems[index].locations}
              insuranceClass={this.billing_selectedItems[index].insuranceClass}
              insuranceCode={this.billing_selectedItems[index].insuranceCode}
              onRef={(ref) => (this.rowNodes[index] = ref)}
              on_unsave_billing={this.on_unsave_billing}
              disable_elements={this.disable_fields}
              claim_delay_reason={this.state.claim_delay_reason}
              row_type={'billing'}
            />
          ) : (
            item.status && (
              <ProviderBillingDisplayRowComponent
                key={index}
                data={item}
                onClickCallbackHandler={this.handleProviderBillingRowClick}
                onClickBadgeItemHandler={this.onClickBadgeItemHandler}
                column_fields={this.state.billing_col_fields}
                row_index={index}
                locations={this.billing_selectedItems[index].locations}
                insuranceClass={this.billing_selectedItems[index].insuranceClass}
                insuranceCode={this.billing_selectedItems[index].insuranceCode}
                onRef={(ref) => (this.rowNodes[index] = ref)}
                on_unsave_billing={this.on_unsave_billing}
                disable_elements={this.disable_fields}
                claim_delay_reason={this.state.claim_delay_reason}
                row_type={'billing'}
              />
            )
          );
        });
      }
      return this.retrieveDefaultProviderBillingRow();
    };

    retrieveDefaultProviderBillingRow = () => {
      const columnsCount = this.retrieveBillingProviderSettingsColumns().length;

      return (
        <tr>
          <td colSpan={columnsCount}>
            <p className='ui-grid-label-empty'>TEXT</p>
          </td>
        </tr>
      );
    };

    new_billing_handler = () => {
        this.setState({
            openPanelMode: {
                isModeEditable: false,
                isModeCreate: true
            }
        });
        this.unbindBillingRowData();
        this.handleDrawerOpen();
    };

    private handleDrawerClose = () => this.setState({ isDrawerOpen: false });
    private handleDrawerOpen = () => this.setState({ isDrawerOpen: true });

    private bindBillingRowData = (selectedRowData, selectedRowIndex) => {
        this.setState({
            providerBilling: {
                originalRowData: selectedRowData,
                originalRowIndex: selectedRowIndex
            }
        });
    };

    private unbindBillingRowData = () => {
        this.setState({
            providerBilling: {
                originalRowData: this.billing_model
            },
            billingTabId: ''
        });
    }

    private handleProviderBillingRowClick = (e, selectedRowData, selectedRowIndex) => {
        this.setState({
            openPanelMode: {
                isModeEditable: true,
                isModeCreate: false
            }
        });
        this.bindBillingRowData(selectedRowData, selectedRowIndex);
        this.handleDrawerOpen();
    }

    private onClickBadgeItemHandler = (itemId) => {
        this.setState({
            billingTabId: itemId
        });
    }

    private getProviderBillingComponent = () => {
        return (
            <ProviderBillingAddEditComponent
                saveHandlerCallback={this.providerBillingSaveCallback}
                {...this.state} />
        );
    };

    private providerBillingSaveCallback = (incomingPayload) => {
        this.validator.setStrategy(new ConcreteStrategySettingNameUnique());
        if (!this.validator.isValid([incomingPayload, this.state.providerBilling.originalRowIndex, this.state.billing_rows])) {
            toaster.error('', billingPaneConstants.settingNameUniqueValidationError);
            return;
        }
        this.validator.setStrategy(new ConcreteStrategyDataInGroups);
        if (!this.validator.isValid(incomingPayload)) {
            toaster.error('', billingPaneConstants.dataInGroupsValidationError);
            return;
        }
        this.validator.setStrategy(new ConcreteStrategyDataRestrictionSpecific);
        if (!this.validator.isValid([incomingPayload, this.state.providerBilling.originalRowIndex, this.state.billing_rows])) {
            toaster.error('', billingPaneConstants.dataRestrictionValidationError);
            return;
        }
        this.setState({
            isDrawerOpen: false,
        });
        var old_data = this.get_all_updated_data();

        if (this.state.openPanelMode.isModeEditable) {
            incomingPayload.hasChanges = this.state.openPanelMode.isModeEditable;
            old_data[this.state.providerBilling.originalRowIndex] = incomingPayload;
            this.billing_selectedItems[this.state.providerBilling.originalRowIndex] = {
                locations: incomingPayload.location_ids,
                insuranceClass: incomingPayload.insurance_class_ids,
                insuranceCode: incomingPayload.insurance_code_ids
            };
        }
        if (this.state.openPanelMode.isModeCreate) {
            old_data.push({ ...incomingPayload });
            this.billing_selectedItems[old_data.length - 1] = {
                locations: incomingPayload.location_ids,
                insuranceClass: incomingPayload.insurance_class_ids,
                insuranceCode: incomingPayload.insurance_code_ids
            };
            this.setState(state => ({
                unsave_billing_rows: [...old_data, old_data.length - 1]
            }))
        }
        
        this.setState(state => ({
            billing_rows: old_data,
            billing_rows_original: [...old_data]
        }));
        session_storage.set('provider_billing_data_loaded', old_data);
    }

    render_claim_hold_rows = () => {
        if (this.state.claim_hold_rows && this.state.claim_hold_rows.length > 0) {
          return this.state.claim_hold_rows.map((item, index) => {
            if (this.claim_hold_selectedItems[index] == undefined) {
              this.claim_hold_selectedItems[index] = {
                locations: item.location_ids,
                insuranceClass: item.insurance_class_ids,
                insuranceCode: item.insurance_code_ids
              };
              }
            return this.state.show_inactive_claim ? (
              <ProviderBillingDisplayRowComponent
                key={index}
                data={item}
                onClickCallbackHandler={this.handleProviderClaimRowClick}
                onClickBadgeItemHandler={this.onClickClaimBadgeItemHandler}
                column_fields={this.state.claim_hold_col_fields}
                row_index={index}
                locations={this.claim_hold_selectedItems[index].locations}
                insuranceClass={this.claim_hold_selectedItems[index].insuranceClass}
                insuranceCode={this.claim_hold_selectedItems[index].insuranceCode}
                onRef={(ref) => (this.rowNodesForClaims[index] = ref)}
                on_unsave_billing={this.on_unsaved_claims}
                disable_elements={this.disable_fields}
                claim_delay_reason={this.state.claim_delay_reason}
                row_type={"claim"}
              />
            ) : (
              item.status && (
                <ProviderBillingDisplayRowComponent
                  key={index}
                  data={item}
                  onClickCallbackHandler={this.handleProviderClaimRowClick}
                  onClickBadgeItemHandler={this.onClickClaimBadgeItemHandler}
                  column_fields={this.state.claim_hold_col_fields}
                  row_index={index}
                  locations={this.claim_hold_selectedItems[index].locations}
                  insuranceClass={this.claim_hold_selectedItems[index].insuranceClass}
                  insuranceCode={this.claim_hold_selectedItems[index].insuranceCode}
                  onRef={(ref) => (this.rowNodesForClaims[index] = ref)}
                  on_unsave_billing={this.on_unsaved_claims}
                  disable_elements={this.disable_fields}
                  claim_delay_reason={this.state.claim_delay_reason}
                  row_type={"claim"}
                />
              )
            );
          });
        }
        return this.retrieveDefaultProviderClaimHoldRow();
    }

    retrieveDefaultProviderClaimHoldRow = () => {
        const columnsCount = this.retrieveProviderClaimHoldsColumns().length;
        return (
          <tr>
            <td colSpan={columnsCount}>
              <p className='ui-grid-label-empty'>TEXT</p>
            </td>
          </tr>
        );
    };

    new_claim_handler = () => {
        this.setState({
        openPanelMode: {
            isModeEditable: false,
            isModeCreate: true
        }
        });
        this.unbindClaimRowData();
        this.handleDrawerClaimOpen();
    };

    private handleDrawerClaimClose = () => this.setState({ isClaimDrawerOpen: false });
    private handleDrawerClaimOpen = () => this.setState({ isClaimDrawerOpen: true });

    private bindClaimRowData = (selectedRowData, selectedRowIndex) => {
        this.setState({
            providerClaimHold: {
                originalRowData: selectedRowData,
                originalRowIndex: selectedRowIndex
            }
        });
    };

    private unbindClaimRowData = () => {
        this.setState({
            providerClaimHold: {
                originalRowData: this.claim_model
            },
            claimTabId: ''
        });
    }

    private handleProviderClaimRowClick = (e, selectedRowData, selectedRowIndex) => {
        this.setState({
          openPanelMode: {
            isModeEditable: true,
            isModeCreate: false
          }
        });
        this.bindClaimRowData(selectedRowData, selectedRowIndex);
        this.handleDrawerClaimOpen();
    }

    private onClickClaimBadgeItemHandler = (itemId) => {
        this.setState({
            claimTabId: itemId
        });
    }

    private getProviderClaimComponent = () => {
        return (
            <ProviderClaimAddEditComponent
                save_disable={this.is_new_claim_button_disable}
                saveHandlerCallback={this.providerClaimSaveCallback}
                {...this.state} />
        );
    };

    private providerClaimSaveCallback = (incomingPayload) => {
        this.validator.setStrategy(new ConcreteStrategyClaimHoldNameUnique());
        if (
          !this.validator.isValid([
            incomingPayload,
            this.state.providerClaimHold.originalRowIndex,
            this.state.claim_hold_rows
          ])
        ) {
          toaster.error('', billingPaneConstants.settingNameUniqueValidationError);
          return;
        }

        this.validator.setStrategy(new ConcreteStrategyOnlyLocationInGroups());
        if (this.validator.isValid(incomingPayload)) {
            this.incomingPayload = incomingPayload;
            this.setState({
                confirm_box_open: true
            });
              return;
        }
        
        this.validator.setStrategy(new ConcreteStrategySingleDataInGroups());
        if (!this.validator.isValid(incomingPayload)) {
          toaster.error('', billingPaneConstants.dataInGroupsValidationErrorClaimHold);
          return;
        }


        this.claim_hold_from_drawer_callback(incomingPayload);       
    }

    claim_hold_from_drawer_callback = (incomingPayload) => {
        this.setState({
            isClaimDrawerOpen: false
        });
          let old_data = this.get_all_updated_claim_data();
  
          if (this.state.openPanelMode.isModeEditable) {
            incomingPayload.hasChanges = this.state.openPanelMode.isModeEditable;
            old_data[this.state.providerClaimHold.originalRowIndex] = incomingPayload;
            this.claim_hold_selectedItems[this.state.providerClaimHold.originalRowIndex] = {
              locations: incomingPayload.location_ids,
              insuranceClass: incomingPayload.insurance_class_ids,
              insuranceCode: incomingPayload.insurance_code_ids
            };
          }
        if (this.state.openPanelMode.isModeCreate) {
            old_data.push({ ...incomingPayload });
            this.claim_hold_selectedItems[old_data.length - 1] = {
              locations: incomingPayload.location_ids,
              insuranceClass: incomingPayload.insurance_class_ids,
              insuranceCode: incomingPayload.insurance_code_ids
            };
            this.setState((state) => ({
              unsaved_claim_rows: [...old_data, old_data.length - 1]
            }));
          }
  
        this.setState((state) => ({
            claim_hold_rows: old_data,
            claim_hold_rows_original: [...old_data]
          }));
          session_storage.set('provider_claim_data_loaded', old_data);
    }

    get_all_updated_data = () => {
        return this.state.billing_rows;
    }

    get_all_updated_claim_data = () => {
        return  this.state.claim_hold_rows;      
    }

    is_data_unsaved = () => {
        if (!this.state.isSaveInProgress) {
            if (JSON.stringify(this.initial_form_object) !== JSON.stringify(this.form_object)) {
                return true;
            } else if (this.state.unsave_billing_rows.length > 0) {
                return true;
            } else if (this.state.unsaved_claim_rows && this.state.unsaved_claim_rows.length > 0) {
                return true;
            }
        }

        return false;
    }

    //Callback on column sorting click
    on_sort_changed = (id, rows) => {
        this.setState({ 
            [`${id}_rows`]: rows 
        });
        this[`${id}_selectedItems`] = [];
    }

    //callback on column reordering drag and drop
    on_column_moved = (id, cols) => {
        this.setState({
            [`${id}_col_fields`]: cols
        });
    }

    // Search Criteria Toggler
    accordion_toggle = (type: React.ReactText) => {
        if (this._is_mounted) {
          this.setState((prev_state) => ({
            controls_is_expended: {
              ...prev_state.controls_is_expended,
              [type]: !prev_state.controls_is_expended[type]
            }
          }));
        }
    };

    // Hide Inactive Settings
    show_inactive = (e) => {
        const { name, checked } = e.target;
        this.setState({
          [name]: checked
        });
    };

    // close popup
    confirm_close_handler = () => {
        if (this._is_mounted) {
        set_focus_on_element_with_id("save_provider_claim_btn");
        this.setState({
            confirm_box_open: false
        });
        }
    };

    // confirm popup handler
    confirm_handler = e => {
        this.claim_hold_from_drawer_callback(this.incomingPayload)
        if (this._is_mounted) {
        this.setState({
            confirm_box_open: false
        });
        }
    };

    //It renders report grid and its search controls.
    render() {
        const { form_object, required_error, is_submitted, by_pass_prompt, controls_is_expended } = this.state;
        return (
            this.state.show_render && (
                <React.Fragment>
                    <Modal
                        closeIcon
                        centered={false}
                        closeOnDimmerClick={false}
                        open={this.state.confirm_box_open}
                        onClose={this.confirm_close_handler}
                        className='confirm_box default-modal'
                        id={'confirm_box'}
                    >
                        <Modal.Header>Confirm</Modal.Header>
                        <Modal.Content>
                        <Modal.Description>
                            <Grid>
                            <Grid.Column>
                                <p>{billingPaneConstants.onlyLocationInGroupsValidationErrorClaimHold}</p>
                            </Grid.Column>
                            </Grid>
                            <Grid>
                            <Grid.Column computer={16} textAlign='right'>
                                <Button type='cancel' basic onClick={this.confirm_close_handler}>
                                Cancel
                                </Button>
                                <Button type='button' id="confirm_button_confirm_box_window" onKeyDown={(e) => {
                                    // handle_focus_on_tab(e, "confirm_box_model_close_button")
                                }} onClick={this.confirm_handler} primary>
                                Continue
                                </Button>
                            </Grid.Column>
                            </Grid>
                        </Modal.Description>
                        </Modal.Content>
                    </Modal>
                    {!by_pass_prompt && (<PromptNavigation
                        is_data_changed={this.is_data_unsaved()}
                        save={e => this.save_handler(e, true)}
                        go_next_location={this.go_next}
                        history={this.props.history}
                    />)}
                    <Dimmer active={this.state.is_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <div className={'admin-wrapper view-edit Locations ' + this.page_metadata.id} style={location_css.locations_id}>
                        <Grid className='headerGrid' style={location_css.headerGrid}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned'>{this.page_metadata.add_header}</h3>
                            </Grid.Column>
                        </Grid>
                        <div className='wrapper' style={location_css.wrapper}>
                            <Form autoComplete='off' name='view_edit_form' className='common-forms' style={location_css.common_forms}>
                                <div id='admin-scrollable-area' style={location_css.scrollbar_area}>
                                    {/* Provider Information */}
                                        <Grid className='common-accordion' style={location_css.provider_top}>
                                            <Grid.Column
                                                computer={16}
                                                tablet={16}
                                                textAlign='left'
                                                className='accordionColumn'
                                                id='accordion-column'
                                            >
                                                <Accordion fluid styled>
                                                    <Accordion.Title active={controls_is_expended.provider} index={0} onClick={() => this.accordion_toggle('provider')}>
                                                        <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                        Provider Information
                                                </Accordion.Title>
                                                    <Accordion.Content active={controls_is_expended.provider} style={location_css.accordion_content}>
                                                        <div id='search-criteria-container'>
                                                            <Grid>
                                                                <Grid.Column tablet={8} computer={4}>
                                                                    <Form.Field>
                                                                        <label>Provider Code{' '}
                                                                            {this.code_id ?
                                                                                <span
                                                                                    className={is_submitted && (this.state.error.provider_code || (!form_object.provider_code && this.required_filed.provider_code)) ? 'req-alert' : 'req-alert_normal'}
                                                                                >
                                                                                    (required)
                                                            </span> : ''
                                                                            }
                                                                        </label>
                                                                        {this.code_id ?
                                                                            <Input
                                                                                autoComplete='off'
                                                                                name='provider_code'
                                                                                id='provider_code'
                                                                                onChange={e => this.input_change_handler(e)}
                                                                                value={this.form_object.provider_code ? this.form_object.provider_code : ''}
                                                                                type='text'
                                                                                maxLength={5}
                                                                                className={is_submitted && (this.state.error.provider_code || (!form_object.provider_code && this.required_filed.provider_code)) ? 'req-border-inp' : ''}
                                                                                disabled={this.disable_fields || !this.page_metadata.provider_enabled}
                                                                            /> :
                                                                            <label>{this.form_object.provider_code}</label>
                                                                        }
                                                                        {this.code_id && is_submitted && this.state.error.provider_code ?
                                                                            <div className='requiredText'>{constants.messages.provider_code_validaion}</div> : ''}
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                                <Grid.Column tablet={8} computer={4}>
                                                                    <Form.Field>
                                                                        <label>
                                                                            First Name{' '}
                                                                            <span
                                                                                className={is_submitted && (this.state.error.first_name || (!form_object.first_name && this.required_filed.first_name)) ? 'req-alert' : 'req-alert_normal'}
                                                                            >
                                                                                (required)
                                                            </span>
                                                                        </label>
                                                                        <Input
                                                                            autoComplete='off'
                                                                            name='first_name'
                                                                            id='first_name'
                                                                            onChange={e => this.input_change_handler(e)}
                                                                            value={this.form_object.first_name ? this.form_object.first_name : ''}
                                                                            type='text'
                                                                            maxLength={50}
                                                                            className={is_submitted && (this.state.error.first_name || (!form_object.first_name && this.required_filed.first_name)) ? 'req-border-inp' : ''}
                                                                            disabled={this.disable_fields || !this.page_metadata.provider_enabled}
                                                                        />
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                                <Grid.Column tablet={8} computer={4}>
                                                                    <Form.Field>
                                                                        <label>
                                                                            Last Name{' '}
                                                                            {this.required_filed.last_name && (
                                                                                <span
                                                                                    className={is_submitted && (this.state.error.last_name || (!form_object.last_name && this.required_filed.last_name)) ? 'req-alert' : 'req-alert_normal'}
                                                                                >
                                                                                    (required)
                                                                </span>
                                                                            )}
                                                                        </label>
                                                                        <Input
                                                                            autoComplete='off'
                                                                            name='last_name'
                                                                            id='last_name'
                                                                            onChange={e => this.input_change_handler(e)}
                                                                            value={this.form_object.last_name ? this.form_object.last_name : ''}
                                                                            type='text'
                                                                            maxLength={50}
                                                                            className={is_submitted && (this.state.error.last_name || (!form_object.last_name && this.required_filed.last_name)) ? 'req-border-inp' : ''}
                                                                            disabled={this.disable_fields || !this.page_metadata.provider_enabled}
                                                                        />
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                                <Grid.Column tablet={8} computer={4}>
                                                                    <Form.Field>
                                                                        <label>
                                                                            Provider Description{' '}
                                                                            {this.required_filed.description && (
                                                                                <span
                                                                                    className={is_submitted && (this.state.error.description || (!form_object.description && this.required_filed.description)) ? 'req-alert' : 'req-alert_normal'}
                                                                                >
                                                                                    (required)
                                                                </span>
                                                                            )}
                                                                        </label>
                                                                        <Input
                                                                            autoComplete='off'
                                                                            name='description'
                                                                            id='description'
                                                                            onChange={e => this.input_change_handler(e)}
                                                                            value={this.form_object.description ? this.form_object.description : ''}
                                                                            type='text'
                                                                            maxLength={50}
                                                                            disabled={this.disable_fields || !this.page_metadata.provider_enabled}
                                                                            className={is_submitted && (this.state.error.description || (!form_object.description && this.required_filed.description)) ? 'req-border-inp' : ''}
                                                                        />
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                            </Grid>
                                                            <Grid>
                                                                <Grid.Column tablet={8} computer={4}>
                                                                    <Form.Field>
                                                                        <label>
                                                                            Provider Type{' '}
                                                                            {this.required_filed.provider_type_id && (
                                                                                <span className={!form_object.provider_type_id && this.required_filed.provider_type_id && is_submitted && required_error.provider_type_id ? 'req-alert' : 'req-alert_normal'}>
                                                                                    (required)
                                                                </span>
                                                                            )}
                                                                        </label>
                                                                        <Selection
                                                                            id='provider_type_id'
                                                                            name='provider_type_id'
                                                                            placeHolder={'Select'}
                                                                            hidden={true}
                                                                            options={this.state.provider_types}
                                                                            onChange={(value, e) => {
                                                                                this.on_change_dropdown_handler(e);
                                                                            }}
                                                                            defaultValue={this.form_object.provider_type_id || ''}
                                                                            style={
                                                                                !form_object.provider_type_id && this.required_filed.provider_type_id && is_submitted && required_error.provider_type_id
                                                                                    ? 'dropdown-options-wrap req-background-inp'
                                                                                    : 'dropdown-options-wrap'
                                                                            }
                                                                            disabled={this.disable_fields || !this.page_metadata.provider_enabled}
                                                                        />
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                                <Grid.Column tablet={8} computer={4}>
                                                                    <Form.Field>
                                                                        <label>
                                                                            Primary Specialty{' '}
                                                                            {this.required_filed.primary_specialty_id && (
                                                                                <span className={!form_object.primary_specialty_id && this.required_filed.primary_specialty_id && is_submitted && required_error.primary_specialty_id ? 'req-alert' : 'req-alert_normal'}>
                                                                                    (required)
                                                                </span>
                                                                            )}
                                                                        </label>
                                                                        <Selection
                                                                            id='primary_specialty_id'
                                                                            name='primary_specialty_id'
                                                                            placeHolder={'Select'}
                                                                            hidden={true}
                                                                            options={this.state.primary_specialty}
                                                                            onChange={(value, e) => {
                                                                                this.on_change_dropdown_handler(e);
                                                                            }}
                                                                            defaultValue={this.form_object.primary_specialty_id || ''}
                                                                            style={
                                                                                !form_object.primary_specialty_id && this.required_filed.primary_specialty_id && is_submitted && required_error.primary_specialty_id
                                                                                    ? 'dropdown-options-wrap req-background-inp'
                                                                                    : 'dropdown-options-wrap'
                                                                            }
                                                                            disabled={this.disable_fields || !this.page_metadata.provider_enabled}
                                                                        />
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                                <Grid.Column tablet={8} computer={4}>
                                                                    <Form.Field>
                                                                        <label>Taxonomy Code</label>
                                                                        <Input
                                                                            id='taxonomy'
                                                                            name='taxonomy'
                                                                            autoComplete='off'
                                                                            value={this.form_object.taxonomy || ''}
                                                                            onChange={e => this.input_change_handler(e)}
                                                                            type='text'
                                                                            maxLength={10}
                                                                            error={is_submitted && this.state.error.taxonomy}
                                                                            disabled={this.disable_fields || !this.page_metadata.provider_enabled}
                                                                        />
                                                                        {is_submitted && this.state.error.taxonomy &&
                                                                            <div className='requiredText'>10 characters</div>}
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                                <Grid.Column tablet={8} computer={4}>
                                                                    <Form.Field>
                                                                        <label>National Provider Identifier</label>
                                                                        <Input
                                                                            id='national_provider_identifier'
                                                                            name='national_provider_identifier'
                                                                            autoComplete='off'
                                                                            value={this.form_object.national_provider_identifier || ''}
                                                                            onChange={e => this.input_change_handler(e)}
                                                                            type='text'
                                                                            maxLength={10}
                                                                            disabled={this.disable_fields || !this.page_metadata.provider_enabled}
                                                                            error={is_submitted && this.state.error.national_provider_identifier}
                                                                        />
                                                                        {is_submitted && this.state.error.national_provider_identifier &&
                                                                            <div className='requiredText'>Invalid NPI (Example 1234567893)</div>}
                                                                    </Form.Field>
                                                                </Grid.Column>

                                                                <Grid.Column tablet={8} computer={4}>
                                                                    <Form.Field>
                                                                        <label>State License</label>
                                                                        <Input
                                                                            id='state_license_number'
                                                                            name='state_license_number'
                                                                            autoComplete='off'
                                                                            onChange={e => this.input_change_handler(e)}
                                                                            value={this.form_object.state_license_number ? this.form_object.state_license_number : ''}
                                                                            type='text'
                                                                            maxLength={50}
                                                                            disabled={this.disable_fields || !this.page_metadata.provider_enabled}
                                                                        />
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                                <Grid.Column tablet={8} computer={4}>
                                                                    <Form.Field>
                                                                        <label>Alt Record ID</label>
                                                                        <Input
                                                                            id='alt_record_id'
                                                                            name='alt_record_id'
                                                                            autoComplete='off'
                                                                            onChange={e => this.input_change_handler(e)}
                                                                            value={this.form_object.alt_record_id ? this.form_object.alt_record_id : ''}
                                                                            type='text'
                                                                            maxLength={50}
                                                                            disabled={this.disable_fields || !this.page_metadata.provider_enabled}
                                                                        />
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                                <Grid.Column tablet={8} computer={4}>
                                                                    <Form.Field >
                                                                        <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                                        <Checkbox
                                                                            name='status'
                                                                            id='id_status'
                                                                            onChange={e => this.checkbox_handler(e)}
                                                                            checked={this.form_object.status ? this.form_object.status : false}
                                                                            label={'Is Active'}
                                                                            disabled={this.disable_fields || !this.page_metadata.provider_enabled}
                                                                        />
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                            </Grid>
                                                        </div>
                                                    </Accordion.Content>
                                                </Accordion>
                                            </Grid.Column>
                                        </Grid>
                                    {/* Provider Claim Holds */}
                                    {this.props.features && this.props.features.isProviderClaimsPaneEnabled &&
                                        <Grid className='common-accordion'>
                                            <Grid.Column
                                                computer={16}
                                                tablet={16}
                                                textAlign='left'
                                                className='accordionColumn'
                                                id='accordion-column'
                                            >
                                                <Accordion fluid styled>
                                                    <Accordion.Title active={controls_is_expended.claim} index={0} onClick={()=>this.accordion_toggle('claim')}>
                                                        <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                        Provider Claim Holds
                                                    </Accordion.Title>
                                                    <Accordion.Content active={controls_is_expended.claim} style={location_css.accordion_content}>
                                                        <Grid style={{ marginTop: '-0.5rem', marginBottom: '-2rem' }}>
                                                            <Grid.Column computer={16} textAlign='left' style={location_css.inactive_column}>
                                                                <Checkbox
                                                                    name='show_inactive_claim'
                                                                    id='show_inactive_claim'
                                                                    onChange={(e) => this.show_inactive(e)}
                                                                    checked={this.state.show_inactive_claim}
                                                                    label={'Show Inactive'}
                                                                    disabled={this.state.claim_hold_rows_original.length <= 0}
                                                                />
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column computer={16}>
                                                                <div className="customtable" id="id_billing_container">
                                                                    <div className="table-responsive" id="id_billing_settings_table">
                                                                        <table className="ui table" id="id-table-billing-settings-form">
                                                                            <thead>
                                                                                <ProviderBillingHeaderComponent
                                                                                    columns={this.state.claim_hold_col_fields}
                                                                                    rows={this.state.claim_hold_rows_original}
                                                                                    onSortChanged={this.on_sort_changed}
                                                                                    onColumnMoved={this.on_column_moved}
                                                                                    id={"claim_hold"}
                                                                                />
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.render_claim_hold_rows()}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column computer={16} textAlign="right" style={location_css.new_button_section}>
                                                                <Button
                                                                    id='id_btn_new_row'
                                                                    type='button'
                                                                    onClick={this.new_claim_handler}
                                                                    primary
                                                                    disabled={this.is_new_claim_button_disable}
                                                                    style={location_css.new_button}
                                                                    content='New'
                                                                />
                                                            </Grid.Column>
                                                        </Grid>
                                                    </Accordion.Content>
                                                </Accordion>
                                            </Grid.Column>
                                        </Grid>
                                    }
                                    {/* Billing Settings */}
                                    {this.props.features && this.page_metadata.billing_enabled &&
                                        <Grid className='common-accordion'>
                                        <Grid.Column
                                            computer={16}
                                            tablet={16}
                                            textAlign='left'
                                            className='accordionColumn'
                                            id='accordion-column'
                                        >
                                            <Accordion fluid styled>
                                                <Accordion.Title active={controls_is_expended.billing} index={0} onClick={()=>this.accordion_toggle('billing')}>
                                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                    Billing Settings
                                                </Accordion.Title>
                                                <Accordion.Content active={controls_is_expended.billing} style={location_css.accordion_content}>
                                                    <Grid style={{ marginTop: '-0.5rem', marginBottom: '-2rem' }}>
                                                        <Grid.Column computer={16} textAlign='left' style={location_css.inactive_column}>
                                                            <Checkbox
                                                                name='show_inactive_billing'
                                                                id='show_inactive_billing'
                                                                onChange={(e) => this.show_inactive(e)}
                                                                checked={this.state.show_inactive_billing}
                                                                label={'Show Inactive'}
                                                                disabled={this.state.billing_rows_original.length <= 0}
                                                            />
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column computer={16}>
                                                            <div className="customtable" id="id_billing_container">
                                                                <div className="table-responsive" id="id_billing_settings_table">
                                                                    <table className="ui table" id="id-table-billing-settings-form">
                                                                        <thead>
                                                                            <ProviderBillingHeaderComponent
                                                                                columns={this.state.billing_col_fields}
                                                                                rows={this.state.billing_rows_original}
                                                                                onSortChanged={this.on_sort_changed}
                                                                                onColumnMoved={this.on_column_moved}
                                                                                id={"billing"}
                                                                            />
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.render_billing_rows()}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column computer={16} textAlign="right" style={location_css.new_button_section}>
                                                            <Button
                                                                id='id_btn_new_row'
                                                                type='button'
                                                                onClick={this.new_billing_handler}
                                                                primary
                                                                disabled={this.disable_fields}
                                                                style={location_css.new_button}
                                                                content="New"
                                                            />
                                                        </Grid.Column>
                                                    </Grid>
                                                </Accordion.Content>
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid>
                                    }
                                </div>
                                <div
                                    className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                    id='applicationFooterSticky'
                                    style={location_css.footerArea}
                                >
                                <Button
                                id='cancel_button_id'
                                type='button'
                                onClick={this.cancel_handler}
                                basic
                                content="Cancel"
                                />
                                <Button
                                id='save_button_id'
                                type='button'
                                disabled={this.state.is_save_button_disabled}
                                onKeyDown={set_focus_to_app_header}
                                onClick={e => this.save_handler(e, false)}
                                primary style={location_css.save_button}
                                content="Save"
                                />
                                </div>
                            </Form>
                        </div>
                    </div>
                    {this.state.isDrawerOpen && <DrawerComponent
                        key={this.props.key_id}
                        canOutsideClickClose={false}
                        canEscapeKeyClose={false}
                        isOpen={this.state.isDrawerOpen}
                        className="ext-bp-drawer provider-drawer billing-provider-drawer"
                        handleDrawerClose={this.handleDrawerClose}
                        position={Position.RIGHT}
                        title=""
                        isCloseButtonShown={true}
                        innerContent={this.getProviderBillingComponent()}
                        size={Drawer.SIZE_STANDARD}
                    />}
                    {this.state.isClaimDrawerOpen &&<DrawerComponent
                        key={this.props.key_id}
                        canOutsideClickClose={false}
                        canEscapeKeyClose={false}
                        isOpen={this.state.isClaimDrawerOpen}
                        className="ext-bp-drawer provider-drawer billing-provider-drawer claim-provider-drawer"
                        handleDrawerClose={this.handleDrawerClaimClose}
                        position={Position.RIGHT}
                        title=""
                        isCloseButtonShown={true}
                        innerContent={this.getProviderClaimComponent()}
                        size={Drawer.SIZE_STANDARD}
                    />}
                </React.Fragment>
            )
        );
    }
}
// Css Style
const location_css = {
    locations_id: {
        paddingBottom: 0
    },
    headerGrid: {
        marginTop: 0,
        marginBottom: 0,
        paddingRight: 15
    },
    wrapper: {
        borderTop: 0,
        marginBottom: 0
    },
    common_forms: {
        paddingTop: 0,
        height: '100%',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,

    patient_search_form: {
        paddingBottom: 0,
        flexBasis: 'auto',
        borderColor: '#cccccc',
        borderStyle: 'solid',
        borderWidth: '2px 1px 1px',
        margin: '0 0 15px 0px',
        background: '#fff',
        padding: '25px 15px'
    },
    accordion_content:{
        padding: 0
    },
    inactive_column:{
        marginBottom: '-1rem'
    },
    new_button_section:{
        marginTop: -10
    },
    new_button:{
        marginRight: 0
    },
    footerArea: {
        paddingLeft: 0,
        paddingBottom: 0,
        marginBottom: 12,
        paddingRight: 25,
        paddingTop: 5
    },
    scrollbar_area: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: 15
    } as React.CSSProperties,
    save_button: {
        marginRight: 0
    },
    provider_top: {
        marginTop: 5
    }
};
//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
        features: {
            isProviderClaimsPaneEnabled: get_lauch_darkley_key_value(state.launch_darkly, constants.providerBillingSettingsFeatures.rsiB31057ProviderClaimsSettingsPane)
        }
    };
};

export default withRouter(connect(mapStateToProps)(ProviderAddEditComponent));
