import { header_template, headerStatusTemplate, statusStyle } from "../../admin/constants/constants";
import { text_comparator } from "../../shared/component/grid/comparators";

export const dynamic_form_letters = {
    id: 'dynamic_form_id',
    search_header: 'Dynamic Form Letters',
    base_url: '/admin',
    session_key: 'patient_letters',
}

// #region UI Elements Name/Id
export const save_button = {
    id: 'pl_save_btn_id',
        name: 'Save'
}

export const preview_button = {
    id: 'pl_preview_btn_id',
    name: 'Print Preview'
}

export const name_textbox = {
    id: 'pl_name_id',
    name:'Name'
}

export const global_checkbox = {
    id: 'pl_global_id',
    name: 'Global'
}

export const active_checkbox = {
    id: 'pl_active_id',
    name: 'Active'
}

export const company_multiselect = {
    id: 'pl_company_select_id',
    name: 'Company'
}

export const letter_body_textarea = {
    id: 'pl_letter_body_id',
    name: ''
}

export const alert = {
    message: 'This action will cause any unsaved data to be lost. Continue?',
    cofirm_btn_name: 'Continue'

}
// #endregion UI Elements Name/Id



// #region Object definitions 
export interface IPatientLetterSearchRequestDto {
    letter_name : string;
    company_id: number;
    show_inactive: boolean;
}

export interface IPatientLetterCompanyDto {
    company_id : number;
    gpms_code: string;
    is_active: boolean;
    is_exclude: boolean;
}

export interface IPatientLetterSearchDataDto {
    patient_letter_id : number;
    patient_letter_name : string; 
    company_list: Array<IPatientLetterCompanyDto>;
    is_global: boolean;
    updated_date: Date;
    updated_by: string;
    is_active: boolean;
}

export interface IStateTemplate {
    search_request_obj: IPatientLetterSearchRequestDto,
    is_loading: {
        company: boolean,
        search_data: boolean,
        patient_letter_variables: boolean,
        patient_letter_edit: boolean
    },
    show_grid: boolean;
    show_drawer: boolean;
    show_save_alert: boolean;
}

export interface ICompanyListDto {
    company_id: string,
    gpms_code: string,
    company_name: string
}

export interface IDisplayFormLettersDto {
    patient_letter_id:number
    patient_letter_name: string,
    company: string,
    global: string,
    last_updated_date_time: string,
    last_updated_user: string,
    status: string
}

export interface IPatientLetterVariableDto {
    patient_letter_variable_name: string,
    patient_letter_variable_value: string
}

export interface IPatientLetterSaveRequestDto {
    patient_letter_id: number;
    patient_letter_name: string;
    patient_letter_content: string;
    company_list: Array<IPatientLetterCompanyDto>;
    is_global: boolean;
    created_date: Date;
    created_by: number;
    updated_date: Date;
    updated_by: number;
    is_active: boolean;
    cloned_from: number;
}

export interface IPatientLetterDetailStateTemplate {
    letter: IPatientLetterSaveRequestDto;
    selected_companies_id_list: Array<number>;
    is_save_in_progress: boolean;
    is_validation_displayed:boolean;
    show_pop_up_menu: boolean;
}

export interface IDrawerState {
    patient_letter_edit: IPatientLetterSaveRequestDto;
    display_patient_letter_id: number;
    is_updated: boolean;
}

//export interface IValidationMessage {
//    message: string;
//    propertyName :string
//}
// #endregion

export const grid_settings =
{
    grid_id: 'grid_dynamic_patient_letters',
    no_records_found_message: 'No Record Available',
    column_defs: [
        {
            headerName: 'Letter Name',
            headerComponentParams: header_template,
            field: 'patient_letter_name',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            resizable: true,
            width: 380,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'Company',
            headerComponentParams: header_template,
            field: 'company',
            tooltipField: 'company',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            resizable: true,
            minWidth:400,
            comparator: text_comparator
        },
        {
            headerName: 'Global',
            headerComponentParams: header_template,
            field: 'global',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            resizable: false,
            width: 120,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'Last Update Date',
            headerComponentParams: header_template,
            field: 'last_updated_date_time',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            width: 220,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'Last Update User',
            headerComponentParams: header_template,
            field: 'last_updated_user',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            width: 200,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'status',
            filter: 'checklistFilter',
            width: 150,
            type: 'string',
            cellStyle: statusStyle,
            unSortIcon: true,
            suppressSizeToFit: true,
        }
    ],
}