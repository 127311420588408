import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, combineReducers } from 'redux';
import { toastr } from 'react-redux-toastr';
import { Button, Dimmer, Form, Grid, Loader, Modal, Divider, Table, GridColumn } from "semantic-ui-react";
import { update_patient_header_info } from '../../patient/action/patient_header_action';
import CurrencyFormatter from '../../shared/component/currency_formatter';
import DatePicker from '../../shared/component/date_picker_component';
import Selection from '../../shared/component/selection_component';
import SelectionSearch from '../../shared/component/selection_search_component';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import AdvancedControl from "../../shared/component/advanced_control";
import { enum_date_type, enum_type_of_search, enum_case_page_state, case_messages } from './../../case/case_constants';
import * as moment from 'moment';
import * as shared_constants from '../../shared/shared_constants';
import * as global_constants from '../../global_constants';
import { R6ResponseData, R6ResponseStatus } from '../../global_constants';
import { quick_search_location, quick_search_provider, search_place, search_provider } from './../../shared/action/autosearch_action';
import ChargeDetailComponent from './charge_detail_component';
import { get_charge_case_list, get_charge_case_pre_auth_list, get_charge_case_summary, add_charge, get_charge_data } from './../action/charge_action';
import DiagnosisComponent from '../../diagnosis/component/diagnosis_component';
import { charge_messages, charge_status, money_type, default_values } from '../charge_constant';
import ConfirmationComponent from '../../shared/component/confirmation_component';
import { prepare_dropdown_data, is_special_char } from './../utility';
import { custom_date_format, getMomentAtEndOfDay, getMomentAtStartOfDay, get_all_error, get_multiple_errors, toBoolean, date_format_with_no_time } from './../../shared/utility';
import * as diagnosis_constant from '../../diagnosis/diagnosis_constant';
import { patientCreditType, PAYMENT_ACTION, payment_const, payment_message } from '../../payment/payment_constants';
import { addChargePayment, saveChargePayment } from '../../payment/action/payment_action';
import ChargePaymentFullModalComponent from './charge_payment_full_modal_component';
import { set_active_patient } from '../../login/action/login_action';
import { get_lauch_darkley_key_value } from '../../shared/utility';
import { adminBillingAuditLogFeatures, ExpectedRateMultipleFeeSchedule } from '../../admin/constants/constants';
interface IState {
    loading: boolean,
    patient_id: string,
    cases: Array<any>,
    pre_auth_list: Array<any>,

    case_pre_auth_list: Array<any>,
    form_submited: boolean,
    location_search_data: any,
    provider_search_data: any,
    treating_provider_search_data: any,
    grid_conf: any,
    is_save_button_disabled: boolean,
    location_quick_search_format:
    {
        "title": string,
        "label": string,
        "pos_code": string
    },
    provider_quick_search_format:
    {
        "title": string,
        "label": string,
        "bp_code": string
    },
    treating_provider_quick_search_format:
    {
        "title": string,
        "label": string,
        "bp_code": string
    },
    stop_payment: boolean,
    stop_save: boolean,
    is_poc_required: boolean,
    is_poc_valid: boolean,
    save_confirmation_box: boolean,
    is_full_payment: boolean,
    is_replacement_checked: boolean,
    is_kx_modifier_replacement_popup: boolean,
    is_kx_modifier_replacement_change_popup: boolean,
    functional_reporting: string,
    kx_modifier_id: number,
    charge: {

        id: number,
        patient_id: number,
        case_id: number,
        pre_auth_id: number,
        injury_date?: Date,
        formatted_injury_date: any,
        plan_of_care_date: any,
        formatted_plan_of_care_date: any,
        date_of_service: any,
        formatted_date_of_service: any,
        //other properties
        provider_id: string,
        treating_provider_id: string,
        company_id: number,
        pos_code: string,
        billing_provider_code: string,
        treating_provider_code: string,
        charge_id: number,
        place_of_service: {
            id: string
        }, billing_provider: {
            id: string
        },
        treating_provider: {
            id: string
        },
        primary_insurance_code: string,
        secondary_insurance_code: string,
        tertiary_insurance_code: string,
        //// other properties

        age: number,
        charge_date_of_service: any,
        charge_injury_date: Date,
        charge_plan_of_care_date: any,
        charges: Array<any>,
        dob?: Date,
        patient_full_name: string,
        description: string,
        is_dont_show_in_menu?: boolean,
        is_allow_scheduled_appointments?: boolean,
        is_pos_schedule_access?: boolean,
        is_all_pos_schedule_access?: boolean,
        is_active?: boolean,
        is_functional_reporting?: boolean,
        status: string,
        is_force_save?: boolean,
        is_kx_modifier_passed?: boolean,
        is_c_p_insurance_checked?: boolean,
        is_replacement_check_passed?: boolean,
        is7th_index_check_passed?: boolean,
        is_functional_reporting_code_missing_passed?: boolean,
        donot_count_visit?: boolean,
        is_functional_reporting_error_passed?: boolean
        //
        selected_pre_auth_from_date?: string,
        selected_pre_auth_thru_date?: string,
        md_code: string,
        start_of_care_date: Date,
        // charges for payment
        charges_for_payment: Array<any>,
        is_multiple_proc_code_for_same_revenue_passed?: boolean

    },
    charge_form_invalid: {
        charge: {
            case: boolean,
            location: boolean,
            date_of_service: boolean,
            place_of_service: boolean,
            provider: boolean,
            plan_of_care: boolean


        }
    },
    charge_details_list: Array<any>,
    old_dx_list: Array<any>,
    charge_error_messages: Array<any>,
    show_warning_popup: boolean,
    show_proc_replacement_warning_popup: boolean,
    show_kx_modifier_popup: boolean,
    show_kx_modifier_popup1: boolean,
    show_cp_insurance_check: boolean,
    show_has_dx_7th_digit_popup: boolean,
    dx_7th_digit_message: string,
    show_missing_functional_reporting_popup: boolean,
    missing_functional_reporting_code_message: string,
    show_functional_reporting_error_popup: boolean,
    functional_reporting_error_message: string,
    show_invalid_case_error_popup: boolean,
    invalid_case_messge: string,
    show_proc_replacement_popup: boolean,
    proc_replacement_list: Array<any>,
    update_charge_details: boolean,
    show_payment_full_modal: boolean,
    transaction_list: Array<any>,
    payment_code_list: Array<any>,
    payment: {
        min_date: any,
        deposit_date: any,
        formatted_deposit_date: any,
        credit_type_id: any,
        payment_code: any,
        amount: any,
        payment_money_type: any,
        check_credit_card: string,
        batch: string,
        comment: string,
        check_num: any,
        cc_num: any,
        billing_provider: any
        place_of_service: any,
        patient_id: any,
        original_amount: any,
        charge_id: any,
        credit_card_auth_num: any,
        patient_credit_type: any

    },
    payment_error: {
        deposit_date: boolean,
        location: boolean,
        payment_code: boolean,
        amount: boolean,
        check_num: boolean,
        insurance: boolean,

    },
    selected_case_data: any,
    payment_full_submitted: boolean,
    payment_loading: boolean,
    charge_review_state: any,
    show_multiple_procode_for_same_revenue_popup: boolean,
    multiple_procode_for_same_revenue_message: string,
    show_multiple_procode_for_same_revenue_confirm_popup: boolean,
    calculate_charge_amount_params: {
        primary_ins_id: number,
        place_of_service_id: number,
        billing_provider_id: number,
        date_of_service: any
    },
    last_date_of_service: any,
    show_bummepd_charge_message_popup: boolean,
    charge_popup_message: string,
    charge_popup_title: string,
    charge_popup_show_cancel: boolean,
    charge_popup_ok_btn_text: string,
    charge_popup_action: any
}

export class AddChargeComponent extends React.Component<any, IState> {
    [x: string]: any;
    _is_mounted = false;
    _is_visit = (this.props.location && this.props.location.state ? this.props.location.state.charge_visit : false);
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            payment_loading: false,
            patient_id: session_storage.get('active_patient'),
            cases: this.props.case_list,
            pre_auth_list: [],
            case_pre_auth_list: [],
            form_submited: false,

            location_search_data: {},
            provider_search_data: {},
            treating_provider_search_data: {},

            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },

            is_save_button_disabled: false,

            location_quick_search_format:
            {
                "title": '',
                "label": '',
                "pos_code": ''
            },
            provider_quick_search_format:
            {
                "title": '',
                "label": '',
                "bp_code": ''
            },
            treating_provider_quick_search_format:
            {
                "title": '',
                "label": '',
                "bp_code": ''
            },
            stop_payment: false,
            stop_save: false,
            is_poc_required: false,
            is_poc_valid: false,
            save_confirmation_box: false,
            is_full_payment: false,
            is_replacement_checked: false,
            is_kx_modifier_replacement_popup: false,
            is_kx_modifier_replacement_change_popup: false,
            kx_modifier_id: 0,
            functional_reporting: '',
            charge: {
                id: this.props.match && this.props.match.params && this.props.match.params.charge_id ? parseInt(this.props.match.params.charge_id) : 0,
                patient_id: parseInt(session_storage.get('active_patient')),
                case_id: this.props.selected_case,
                pre_auth_id: 0,
                injury_date: null,
                formatted_injury_date: null,
                plan_of_care_date: null,
                formatted_plan_of_care_date: null,
                date_of_service: null,
                formatted_date_of_service: null,
                //other properties
                charge_id: this.props.match && this.props.match.params && this.props.match.params.charge_id ? parseInt(this.props.match.params.charge_id) : 0,
                provider_id: null,
                treating_provider_id: null,

                company_id: null,

                pos_code: null,
                billing_provider_code: null,
                treating_provider_code: null,


                place_of_service: {
                    id: "-999"
                }, billing_provider: {
                    id: "-999"
                },
                treating_provider: {
                    id: "-1"
                },
                primary_insurance_code: 'No Primary Insurance',
                secondary_insurance_code: 'No Secondary Insurance',
                tertiary_insurance_code: 'No Tertiary Insurance',
                //Other properties               

                age: null,
                charge_date_of_service: null,
                charge_injury_date: null,
                charge_plan_of_care_date: null,
                charges: [],
                dob: new Date('01/01/1970'),
                patient_full_name: null,
                description: null,
                is_dont_show_in_menu: null,
                is_allow_scheduled_appointments: null,
                is_pos_schedule_access: null,
                is_all_pos_schedule_access: null,
                is_active: null,
                is_functional_reporting: false,
                status: null,
                is_force_save: false,
                is_kx_modifier_passed: false,
                is_c_p_insurance_checked: false,
                is_replacement_check_passed: false,
                is7th_index_check_passed: false,
                is_functional_reporting_code_missing_passed: false,
                donot_count_visit: false,
                is_functional_reporting_error_passed: false,
                ///
                selected_pre_auth_from_date: '',
                selected_pre_auth_thru_date: '',
                md_code: '',
                start_of_care_date: null,
                charges_for_payment: [],
                is_multiple_proc_code_for_same_revenue_passed: false
            },
            charge_form_invalid: {
                charge: {
                    case: false,
                    location: false,
                    date_of_service: false,
                    place_of_service: false,
                    provider: false,
                    plan_of_care: false

                }
            },
            charge_details_list: [],
            old_dx_list: [],
            charge_error_messages: [],
            show_warning_popup: false,
            show_proc_replacement_warning_popup: false,
            show_kx_modifier_popup: false,
            show_kx_modifier_popup1: false,
            show_cp_insurance_check: false,
            show_has_dx_7th_digit_popup: false,
            dx_7th_digit_message: '',
            show_missing_functional_reporting_popup: false,
            missing_functional_reporting_code_message: '',
            show_functional_reporting_error_popup: false,
            functional_reporting_error_message: '',
            show_invalid_case_error_popup: false,
            invalid_case_messge: '',
            show_proc_replacement_popup: false,
            proc_replacement_list: [],
            update_charge_details: false,
            show_payment_full_modal: false,
            transaction_list: [],
            payment_code_list: [],
            payment: {
                min_date: null,
                deposit_date: null,
                formatted_deposit_date: null,
                credit_type_id: '',
                payment_code: {
                    payment_id: ''
                },
                amount: '',
                payment_money_type: 3,
                check_credit_card: '',
                batch: '',
                check_num: '',
                cc_num: '',
                comment: '',
                billing_provider: {
                    id: ''
                },
                place_of_service: {
                    id: ''
                },
                patient_id: '',
                original_amount: '',
                charge_id: '',
                credit_card_auth_num: '',
                patient_credit_type: {
                    credit_type_id: 1,
                    credit_type: 'Copay'
                }

            },
            payment_error: {
                deposit_date: false,
                location: false,
                payment_code: false,
                amount: false,
                check_num: false,
                insurance: false,

            },
            selected_case_data: {},
            payment_full_submitted: false,
            charge_review_state: {},
            show_multiple_procode_for_same_revenue_popup: false,
            multiple_procode_for_same_revenue_message: '',
            show_multiple_procode_for_same_revenue_confirm_popup: false,
            calculate_charge_amount_params: {
                primary_ins_id: null,
                place_of_service_id: null,
                billing_provider_id: null,
                date_of_service: null
            },
            last_date_of_service: null,
            show_bummepd_charge_message_popup: false,
            charge_popup_message: '',
            charge_popup_title: '',
            charge_popup_show_cancel: true,
            charge_popup_ok_btn_text: '',
            charge_popup_action: () => { }
        }
        this.diagnosis_child = React.createRef();
        this.charge_detail_child = React.createRef();
    }
    case_change = (case_id, is_case_summary_update = true, is_edit = false) => {
        let patient_id = session_storage.get('active_patient');
        if (is_case_summary_update) {
            this.get_case_summary(patient_id, case_id, is_edit);
        }
        //
        this.get_pre_auth_list(case_id);
        let invalid_charge_form = this.state.charge_form_invalid;
        invalid_charge_form.charge.case = false;
        invalid_charge_form.charge.date_of_service = false;
        invalid_charge_form.charge.location = false;
        invalid_charge_form.charge.place_of_service = false;
        invalid_charge_form.charge.plan_of_care = false;
        invalid_charge_form.charge.provider = false;
        //Reset all the validation to false when case changes
        if (this._is_mounted) {
            this.setState({
                charge_form_invalid: invalid_charge_form
            });
        }
    }
    reset_after_save = (case_id) => {
        let state_charge = this.state.charge;
        this.case_change(case_id, false);
        //re-set charge list
        if (this.charge_detail_child && this.charge_detail_child.default_charge_row) {
            this.charge_detail_child.default_charge_row();
        }
        if (this.state.charge.charge_id > 0) {
            if (this.state.cases.length > 2) {
                this.state.charge.case_id = 0;
            }
            this.state.charge.charge_id = 0;


            if (this._is_mounted) {
                this.setState({
                    form_submited: false,
                    functional_reporting: '',
                    stop_payment: true,
                    stop_save: true,
                    is_poc_required: false,
                    is_poc_valid: false,
                    charge: state_charge
                }, () => { this.props.set_parent_selected_case(state_charge.case_id, state_charge.charge_id); this.props.history.push(`/charge/add_charge`) });
            }

        }
        else {
            state_charge.case_id = case_id;
            state_charge.date_of_service = null;
            state_charge.formatted_date_of_service = null;
            state_charge.charge_date_of_service = null;

            if (this._is_mounted) {
                this.setState({
                    functional_reporting: '',
                    stop_payment: true,
                    stop_save: true,
                    is_poc_required: false,
                    is_poc_valid: false,
                    charge: state_charge,
                }, () => {
                    this.props.set_parent_selected_case(case_id, state_charge.charge_id);
                }
                );
            }

        }

    }
    charge_case_info_change = (object, e) => {
        let state_charge = this.state.charge;
        let _state = this.state;
        if (object.name == 'case' && this._is_mounted) {
            this.case_change(object.value, true, this.state.charge.charge_id > 0);
            state_charge.case_id = object.value;
            if (this._is_mounted) {
                this.setState({
                    stop_payment: true,
                    stop_save: true,
                    is_poc_required: false,
                    is_poc_valid: false,
                    charge: state_charge,
                }, () => { this.props.set_parent_selected_case(object.value, state_charge.charge_id) })
            }
        }
        if (object.name == 'pre_auth' && this._is_mounted) {
            state_charge.pre_auth_id = object.value;
            //set the selected pre-auth from and thru date
            if (object.value && object.value > 0) {
                this.state.case_pre_auth_list.map((value, key) => {
                    if (value.id.toString() === object.value.toString()) {
                        state_charge.selected_pre_auth_from_date = value.from_date;
                        state_charge.selected_pre_auth_thru_date = value.thru_date;
                    }
                })
            }
            if (this._is_mounted) {
                this.setState({
                    charge: state_charge
                });
            }
        }

    }

    get_pre_auth_list = async (case_id) => {

        await get_charge_case_pre_auth_list(this.props.user_login_details.user_data.data.token_details.access_token, case_id, false).then(response => {
            if (response.data.data) {
                let pre_auth_list = response.data.data;
                for (var i = 0; i < pre_auth_list.length; i++) {
                    //format dates
                    let from_date = this.convert_date(pre_auth_list[i].from_date);
                    let thru_date = this.convert_date(pre_auth_list[i].thru_date);
                    let unit_visit = pre_auth_list[i].is_units == true ? 'U' : 'V';
                    pre_auth_list[i].display_auth = '';
                    if (pre_auth_list[i].pre_auth_number != null) {
                        pre_auth_list[i].display_auth += ' ' + pre_auth_list[i].pre_auth_number.toString() + ' |';
                    }
                    if (pre_auth_list[i].from_date == null) {
                        pre_auth_list[i].display_auth += ' ' + charge_messages.no_from_date + ' -';
                    }
                    else {
                        pre_auth_list[i].display_auth += ' ' + from_date + ' -';
                    }
                    if (pre_auth_list[i].thru_date == null) {
                        pre_auth_list[i].display_auth += ' ' + charge_messages.no_thru_date + ' |';
                    }
                    else {
                        pre_auth_list[i].display_auth += ' ' + thru_date + ' |';
                    }
                    if (pre_auth_list[i].pre_auth_visits != null && pre_auth_list[i].pre_auth_visits > 0) {
                        pre_auth_list[i].display_auth += ' ' + pre_auth_list[i].pre_auth_visits + unit_visit + ' - ' + pre_auth_list[i].visits_used + unit_visit;
                    }
                    else {
                        pre_auth_list[i].display_auth += ' ' + charge_messages.no_visit;
                    }

                    if (this._is_mounted) {
                        this.setState({
                            pre_auth_list: prepare_dropdown_data(pre_auth_list, false),
                            case_pre_auth_list: pre_auth_list
                        });
                    }
                }

            }
            else {
                if (this._is_mounted) {
                    this.setState({
                        pre_auth_list: prepare_dropdown_data([], false)
                    });
                }
            }
        });
        this.get_old_dx_list();
    }
    get_case_summary = async (patient_id, case_id, is_edit = false) => {

        await get_charge_case_summary(this.props.user_login_details.user_data.data.token_details.access_token, patient_id, case_id).then(response => {
            if (response.data.data) {
                let charge_state = this.state.charge;

                charge_state.primary_insurance_code = response.data.data.primary_insurance_code == null ? 'No Primary Insurance' : response.data.data.primary_insurance_code;
                charge_state.secondary_insurance_code = response.data.data.secondary_insurance_code == null ? 'No Secondary Insurance' : response.data.data.secondary_insurance_code;
                charge_state.tertiary_insurance_code = response.data.data.tertiary_insurance_code == null ? 'No Tertiary Insurance' : response.data.data.tertiary_insurance_code;
                charge_state.md_code = response.data.data.md_code;
                charge_state.start_of_care_date = response.data.data.start_of_care_date;
                charge_state.is_functional_reporting = response.data.data.is_functional_reporting;
                charge_state.formatted_injury_date = null;
                // Set date object for injury Date 
                if (!(response.data.data.injury_date == '' || response.data.data.injury_date == null || response.data.data.injury_date == undefined)) {
                    charge_state.formatted_injury_date = moment(response.data.data.injury_date);
                    charge_state.injury_date = response.data.data.injury_date;
                }
                else {
                    charge_state.formatted_injury_date = null;
                    charge_state.injury_date = response.data.data.injury_date;
                }
                this.setState({ calculate_charge_amount_params: { ...this.state.calculate_charge_amount_params, primary_ins_id: response.data.data.primary_insurance_code_id } });
                if (!is_edit) {

                    charge_state.formatted_plan_of_care_date = null;
                    charge_state.formatted_date_of_service = null;

                    // Set date object  for start of care date
                    if (!(response.data.data.plan_of_care_date == '' || response.data.data.plan_of_care_date == null || response.data.data.plan_of_care_date == undefined)) {
                        charge_state.formatted_plan_of_care_date = moment(response.data.data.plan_of_care_date);
                    }
                    // Set date object  for start of care date
                    if (!(response.data.data.date_of_service == '' || response.data.data.date_of_service == null || response.data.data.date_of_service == undefined)) {
                        charge_state.formatted_date_of_service = moment(response.data.data.date_of_service);
                        charge_state.charge_date_of_service = response.data.data.date_of_service;
                    }
                    var place_of_service_format = { "label": '', "title": '', "pos_code": '' };
                    var billing_provider_format = { "label": '', "title": '', "bp_code": '' };
                    var treating_provider_format = { "label": '', "title": '', "bp_code": '' };
                    if (response.data.data.place_of_service.id > 0) {
                        charge_state.place_of_service.id = response.data.data.place_of_service.id;
                        place_of_service_format = {
                            "title": response.data.data.place_of_service.id.toString(),
                            "pos_code": response.data.data.place_of_service.pos_code,
                            "label": (response.data.data.place_of_service.code) + " - " + (response.data.data.place_of_service.name)

                        };
                    }
                    if (response.data.data.billing_provider.id > 0) {
                        charge_state.billing_provider.id = response.data.data.billing_provider.id;
                        billing_provider_format = {
                            "title": response.data.data.billing_provider.id.toString(),
                            "bp_code": response.data.data.billing_provider.pos_code,
                            "label": (response.data.data.billing_provider.code) + " - " + (response.data.data.billing_provider.description)

                        };
                    }
                    this.setState({
                        calculate_charge_amount_params: {
                            ...this.state.calculate_charge_amount_params,
                            place_of_service_id: response.data.data.place_of_service.id,
                            billing_provider_id: response.data.data.billing_provider.id,
                            date_of_service: date_format_with_no_time(charge_state.formatted_date_of_service)
                        },
                        last_date_of_service: date_format_with_no_time(charge_state.formatted_date_of_service)
                    });

                    var treating_provider_label = response.data.data.treating_provider == null ? '' : (response.data.data.treating_provider.code == null || response.data.data.treating_provider.code == '0' || response.data.data.treating_provider.code == '' || response.data.data.treating_provider.id == 0 || response.data.data.treating_provider.id == -1 ? '' : response.data.data.treating_provider.code + ' - ') + (response.data.data.treating_provider.description == null ? '' :
                        response.data.data.treating_provider.description);
                    if (treating_provider_label != '') {
                        charge_state.treating_provider.id = response.data.data.treating_provider.id;
                        treating_provider_format = {
                            "title": response.data.data.treating_provider.id.toString(),
                            "bp_code": response.data.data.treating_provider.code,
                            "label": treating_provider_label

                        };
                    }
                }
                else {
                    this.check_fun_reporting();
                }
                charge_state.case_id = response.data.data.id;
                if (!is_edit) {
                    if (this._is_mounted) {
                        this.setState({
                            location_quick_search_format: place_of_service_format,
                            provider_quick_search_format: billing_provider_format,
                            treating_provider_quick_search_format: treating_provider_format,
                            charge: charge_state,
                            selected_case_data: response.data.data
                        });
                    }
                }
                else {
                    if (this._is_mounted) {
                        this.setState({
                            charge: charge_state,
                            selected_case_data: response.data.data
                        });
                    }
                }
                // while calender scroll to top for resolution 1366*768                
                if (window.screen.width == 1366 && window.screen.height == 768) {
                    if (document.querySelector("#charge_date_of_service")) {
                        document.querySelector("#charge_date_of_service").addEventListener('focus', this.date_picker_focus)
                    }
                    if (document.querySelector("#charge_plan_of_care_date")) {
                        document.querySelector("#charge_plan_of_care_date").addEventListener('focus', this.date_picker_focus)
                    }
                }
            }

        });
    }
    get_charge_details = () => {
        get_charge_data(this.props.user_login_details.user_data.data.token_details.access_token, this.state.charge.charge_id, this._is_visit).then(response => {

            let state_charge = this.state.charge;

            if (response.data.data) {
                state_charge.case_id = response.data.data.id;

                //update new patient id
                var oldPatientId = session_storage.get('active_patient');
                if (!(oldPatientId == null && oldPatientId == undefined) && oldPatientId != this.state.patient_id) {
                    session_storage.remove('active_case');
                }
                session_storage.set('active_patient', response.data.data.patient_id);
                this.props.set_active_patient(response.data.data.patient_id);
                //update header case if patient change
                this.props.update_patient_header_info(true, state_charge.case_id, true);

                if (response.data.data.id > 0) {
                    this.case_change(response.data.data.id, true, true);
                }
                state_charge.pre_auth_id = response.data.data.pre_auth_id;
                state_charge.charges = response.data.data.charges;
                // Set date object for injury Date 
                if (!(response.data.data.date_of_service == '' || response.data.data.date_of_service == null || response.data.data.date_of_service == undefined)) {
                    state_charge.formatted_date_of_service = date_format_with_no_time(response.data.data.date_of_service);
                    state_charge.charge_date_of_service = response.data.data.date_of_service;
                }
                //
                state_charge.donot_count_visit = response.data.data.donot_count_visit;
                state_charge.is7th_index_check_passed = response.data.data.is7th_index_check_passed;
                state_charge.is_c_p_insurance_checked = response.data.data.is_c_p_insurance_checked;

                state_charge.is_force_save = response.data.data.is_force_save;
                state_charge.is_functional_reporting = response.data.data.is_functional_reporting;
                state_charge.is_functional_reporting_code_missing_passed = response.data.data.is_functional_reporting_code_missing_passed;
                state_charge.is_kx_modifier_passed = response.data.data.is_kx_modifier_passed;
                state_charge.is_replacement_check_passed = response.data.data.is_replacement_check_passed;
                state_charge.is_multiple_proc_code_for_same_revenue_passed = response.data.data.is_multiple_proc_code_for_same_revenue_passed;

                //
                // Set date object for injury Date 
                if (!(response.data.data.plan_of_care_date == '' || response.data.data.plan_of_care_date == null || response.data.data.plan_of_care_date == undefined)) {
                    state_charge.formatted_plan_of_care_date = moment(response.data.data.plan_of_care_date);
                }
                var place_of_service_format = { "label": '', "title": '', "pos_code": '' };
                var billing_provider_format = { "label": '', "title": '', "bp_code": '' };
                var treating_provider_format = { "label": '', "title": '', "bp_code": '' };

                if (response.data.data.place_of_service.id > 0) {
                    state_charge.place_of_service.id = response.data.data.place_of_service.id;
                    place_of_service_format = {
                        "title": response.data.data.place_of_service.id.toString(),
                        "pos_code": response.data.data.place_of_service.pos_code,
                        "label": (response.data.data.place_of_service.code) + " - " + (response.data.data.place_of_service.name)

                    };
                }
                if (response.data.data.billing_provider.id > 0) {
                    state_charge.billing_provider.id = response.data.data.billing_provider.id;
                    billing_provider_format = {
                        "title": response.data.data.billing_provider.id.toString(),
                        "bp_code": response.data.data.billing_provider.code,
                        "label": (response.data.data.billing_provider.code) + " - " + (response.data.data.billing_provider.description)

                    };
                }
                var treating_provider_label = response.data.data.treating_provider == null ? '' : (response.data.data.treating_provider.code == null || response.data.data.treating_provider.code == '0' || response.data.data.treating_provider.code == '' || response.data.data.treating_provider.id == 0 || response.data.data.treating_provider.id == -1 ? '' : response.data.data.treating_provider.code + ' - ') + (response.data.data.treating_provider.description == null ? '' :
                    response.data.data.treating_provider.description);
                if (treating_provider_label != '') {
                    state_charge.treating_provider.id = response.data.data.treating_provider.id;
                    treating_provider_format = {
                        "title": response.data.data.treating_provider.id.toString(),
                        "bp_code": response.data.data.treating_provider.code,
                        "label": treating_provider_label

                    };
                }
                if (this._is_mounted) {
                    this.setState({
                        location_quick_search_format: place_of_service_format,
                        provider_quick_search_format: billing_provider_format,
                        treating_provider_quick_search_format: treating_provider_format,
                        charge: state_charge,
                        patient_id: response.data.data.patient_id,
                        selected_case_data: response.data.data,
                        calculate_charge_amount_params: {
                            ...this.state.calculate_charge_amount_params,
                            place_of_service_id: Number(state_charge.place_of_service.id),
                            billing_provider_id: Number(state_charge.billing_provider.id),
                            date_of_service: date_format_with_no_time(state_charge.formatted_date_of_service)
                        },
                        last_date_of_service: date_format_with_no_time(state_charge.formatted_date_of_service)
                    }, () => {
                        this.props.set_parent_selected_case(state_charge.case_id, state_charge.charge_id);
                        this.charge_detail_child.set_charge_detail_data(state_charge.charges);
                    });
                }
            }

        })
    }
    componentDidMount = () => {
        this._is_mounted = true;
        if (this.state.charge.charge_id > 0) {
            this.get_charge_details();
        }
        else {
            if (this.state.charge.case_id > 0) {
                this.case_change(this.state.charge.case_id);
            }
        }
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        //document.getElementById('charge_case').focus();
    }

    componentDidUpdate(previousProps, previousState) {
        if (this._is_mounted) {
            if (JSON.stringify(previousProps.case_list) !== JSON.stringify(this.props.case_list)) {
                this.setState({ cases: this.props.case_list, patient_id: session_storage.get('active_patient') }, () => { }); // show on change props
                //re-set charge list
                if (this.charge_detail_child && this.charge_detail_child.default_charge_row) {
                    this.charge_detail_child.default_charge_row();
                }
            }
            if (previousProps.selected_case !== this.props.selected_case) {
                if (this.props.selected_case != this.state.charge.case_id) {
                    let charge = this.state.charge;
                    charge.case_id = this.props.selected_case;
                    this.setState({ charge: charge }, () => {
                        if (this.props.selected_case > 0) {
                            this.case_change(this.props.selected_case);
                        }
                    });

                }
            }
            // Update state if the user changes url from add->update and vice versa
            if (previousProps.location.pathname != this.props.location.pathname) {
                if (this.props.location.pathname == '/charge/add_charge') {
                    let charge = this.state.charge;
                    charge.case_id = 0;
                    charge.charge_id = 0;
                    this.setState({
                        charge: charge
                    });
                    this.reset_after_save(0);
                }
            }
        }
    }
    componentWillUnmount = () => {
        this._is_mounted = false;
    }
    on_blur_auto_search = (e, type_of_search) => {
        var charge_data = this.state.charge;
        var charge_invalid = this.state.charge_form_invalid;
        var elem_location_value = document.getElementById('location')['value'];
        var elem_provider_value = document.getElementById('billing_provider')['value'];
        if (type_of_search == enum_type_of_search.place_of_service) {
            if (elem_location_value == "") {
                charge_invalid.charge.place_of_service = true;
            }
            else {
                charge_invalid.charge.place_of_service = false;
            }
            if (this._is_mounted) {
                this.setState({
                    charge_form_invalid: charge_invalid
                });
            }
        }
        else if (type_of_search == enum_type_of_search.provider) {
            if (elem_provider_value == "") {
                charge_invalid.charge.provider = true;
            }
            else {
                charge_invalid.charge.provider = false;
            }
            if (this._is_mounted) {
                this.setState({
                    charge_form_invalid: charge_invalid
                });
            }
        }

    }
    on_location_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.place_of_service, selected_row);
    }
    on_provider_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.provider, selected_row);
    }
    on_treating_provider_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.treating_provider, selected_row);
    }
    on_row_selection_advance_search =
        (type_of_search, selected_row) => {
            var id = '';
            var name = '';
            var code = '';
            var selected_data = '';
            var charge_state = this.state.charge;
            var charge_invalid = this.state.charge_form_invalid;

            if (type_of_search === enum_type_of_search.place_of_service) {
                if (selected_row) {
                    id = selected_row.id;
                    if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                        code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                        name = (selected_row.name == null && selected_row.name == undefined) ? '' : selected_row.name;
                    }
                }
                selected_data = code + " - " + name;
                var format_loc = { "label": '', "title": '', "pos_code": '' };
                if (parseInt(id) > 0) {
                    // Set Auto search control for location
                    format_loc = {
                        "title": id.toString(),
                        "pos_code": code,
                        "label": selected_data
                    };
                }
                charge_state.pos_code = charge_state.place_of_service.id = id;
                charge_invalid.charge.location = false;
                if (this._is_mounted) {
                    this.setState({
                        location_quick_search_format: format_loc,
                        charge: charge_state,
                        charge_form_invalid: charge_invalid,
                        calculate_charge_amount_params: { ...this.state.calculate_charge_amount_params, place_of_service_id: Number(id) }
                    });
                    this.recalculate_charge_amount();
                }
            }
            else if (type_of_search === enum_type_of_search.provider) {
                if (selected_row) {
                    id = selected_row.id;
                    if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                        code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                        name = (selected_row.description == null && selected_row.description == undefined) ? '' : selected_row.description;
                    }
                }
                selected_data = code + " - " + name;
                var format_bp = { "label": '', "title": '', "bp_code": '' };
                if (parseInt(id) > 0) {
                    // Set Auto search control for provider
                    format_bp = {
                        "title": id.toString(),
                        "bp_code": code,
                        "label": selected_data
                    };
                }

                charge_state.billing_provider_code = charge_state.billing_provider.id = id;
                charge_invalid.charge.provider = false;

                if (this._is_mounted) {
                    this.setState({
                        provider_quick_search_format: format_bp,
                        charge: charge_state,
                        charge_form_invalid: charge_invalid,
                        calculate_charge_amount_params: { ...this.state.calculate_charge_amount_params, billing_provider_id: Number(id) }
                    });
                    this.recalculate_charge_amount();
                }
            }
            else if (type_of_search === enum_type_of_search.treating_provider) {
                if (selected_row) {
                    id = selected_row.id;
                    if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                        code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                        name = (selected_row.description == null && selected_row.description == undefined) ? '' : selected_row.description;
                    }
                }
                selected_data = code + " - " + name;
                var format_bp = { "label": '', "title": '', "bp_code": '' };
                if (parseInt(id) > 0) {
                    // Set Auto search control for provider
                    format_bp = {
                        "title": id.toString(),
                        "bp_code": code,
                        "label": selected_data
                    };
                }

                charge_state.treating_provider_code = charge_state.treating_provider.id = id;
                //charge_invalid.charge.provider = false;

                if (this._is_mounted) {
                    this.setState({
                        treating_provider_quick_search_format: format_bp,
                        charge: charge_state,
                        charge_form_invalid: charge_invalid
                    });
                }
            }

        }
    //Auto search for Location
    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }
    // function/method to handle the when searching of location occurs
    on_location_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.place_of_service, params);
    }
    // function/method to handle the when searching of provider occurs
    on_provider_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.provider, params);
    }
    // function/method to handle the when searching of provider occurs
    on_treating_provider_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.treating_provider, params);
    }
    configure_grid_data = async (type_of_search, params) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        params = params ? params : {};
        // params.page_size = 100;
        var grid_data = [];
        if (type_of_search === enum_type_of_search.place_of_service) {
            const search_data_location = await search_place(params, token).then(res => res.data);
            const search_location_result = search_data_location.data !== null ? search_data_location.data.result : [];
            grid_data = { ...this.state.grid_conf, rows: search_location_result, column: shared_constants.search_location_configuration(type_of_search + 'grid_header_id').column_defs, messages: search_data_location.messages };
            if (this._is_mounted) {
                this.setState({ location_search_data: grid_data });
            }
        }
        else if (type_of_search === enum_type_of_search.provider) {

            params.id = this.state.charge.pos_code;
            params.apply_pos_code = false;
            const search_data_provider = await search_provider(params, token).then(res => res.data);
            const search_provider_result = search_data_provider.data !== null ? search_data_provider.data.result : [];
            grid_data = { ...this.state.grid_conf, rows: search_provider_result, column: shared_constants.search_provider_configuration(type_of_search + 'grid_header_id').column_defs, messages: search_data_provider.messages };
            if (this._is_mounted) {
                this.setState({ provider_search_data: grid_data });
            }
        }
        else if (type_of_search === enum_type_of_search.treating_provider) {

            params.id = this.state.charge.pos_code;
            params.apply_pos_code = false;
            params.provider_type = 2;
            const search_data_provider = await search_provider(params, token, true).then(res => res.data);
            const search_provider_result = search_data_provider.data !== null ? search_data_provider.data.result : [];
            grid_data = { ...this.state.grid_conf, rows: search_provider_result, column: shared_constants.search_provider_configuration(type_of_search + 'grid_header_id').column_defs, messages: search_data_provider.messages };
            if (this._is_mounted) {
                this.setState({ treating_provider_search_data: grid_data });
            }
        }

    }
    // get the quick search location
    get_location_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_location(search_keyword, token);
    }
    // get the quick search provider
    get_provider_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_provider(search_keyword, token, 0);
    }
    // get the quick search provider
    get_treating_provider_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_provider(search_keyword, token, 0, true);
    }
    // select the item on click suggested items
    on_item_selection = (item, type) => {
        var charge_state = this.state.charge;
        var charge_invalid = this.state.charge_form_invalid;
        if (type === enum_type_of_search.place_of_service) {
            charge_invalid.charge.location = false;
            charge_state.pos_code = charge_state.place_of_service.id = item.title;
            if (this._is_mounted) {
                this.setState({
                    charge: charge_state, charge_form_invalid: charge_invalid,
                    calculate_charge_amount_params: { ...this.state.calculate_charge_amount_params, place_of_service_id: Number(item.title) }
                });
            }
            this.recalculate_charge_amount();
        }
        else if (type === enum_type_of_search.provider) {
            charge_state.billing_provider_code = charge_state.billing_provider.id = item.title;
            charge_invalid.charge.provider = false;
            if (this._is_mounted) {
                this.setState({
                    charge: charge_state, charge_form_invalid: charge_invalid,
                    calculate_charge_amount_params: { ...this.state.calculate_charge_amount_params, billing_provider_id: Number(item.title) }
                });
            }
            this.recalculate_charge_amount();
        }
        else if (type === enum_type_of_search.treating_provider) {
            charge_state.treating_provider_code = charge_state.treating_provider.id = item.title;
            if (this._is_mounted) {
                this.setState({ charge: charge_state });
            }
        }
    }
    // preapare the suggestion list with search result
    prepare_suggestion = (_data, type_of_search) => {
        let formattedList = [];
        var data_length = 0;
        if (_data.data) {
            data_length = _data.data.length;
            _data = _data.data;
        }
        else {
            data_length = _data.length;
        }
        if (_data && data_length) {
            for (var i = 0; i < data_length; i++) {
                var item = _data[i];
                if (type_of_search === enum_type_of_search.place_of_service) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.code,
                        "name": item.name,

                        "label": (item.code) + " - " + (item.name),

                    })
                }
                else if (type_of_search === enum_type_of_search.provider || type_of_search === enum_type_of_search.treating_provider) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.code,
                        "name": item.description,

                        "label": (item.code) + " - " + (item.description),

                    })
                }

            }

        }
        return formattedList;
    }
    convert_date = (input_format) => {
        if (input_format == null) {
            return ''
        }
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(input_format);
        return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/');
    }

    additional_charge_validate = () => {
        let state_charge = this.state.charge;
        let in_valid_form_data = false;
        let from_date = getMomentAtStartOfDay(state_charge.selected_pre_auth_from_date);
        let thru_date = getMomentAtStartOfDay(state_charge.selected_pre_auth_thru_date);
        let min_date = getMomentAtStartOfDay('1-1-0001');
        // dos format does not match with pre_auth dates causing comparison error
        let dos = getMomentAtStartOfDay(moment(state_charge.date_of_service).format('YYYY-MM-DDTHH:mm:ss'));
        let valid_from_date = true;
        let valid_thru_date = true;
        let current_date = getMomentAtEndOfDay(new Date());
        let _state = Object.assign({}, this.state);
        let has_charge_amount = true;
        let has_charges = true;
        let is_proc_code_found = false;
        let unit_req = true;
        let is_req_field_valid = true;
        let valid_dx_sequence = [];
        let row_dx_sequence = [];
        let dx_error_msg = "";
        var sequence = "0";
        let count = 0;
        let has_duplicate_dx = false;
        let already_added = false;
        let has_no_dx = false;

        // array to store all the messages to be shown on toaster 
        let messages = [];
        //check for primary insuarance
        if (state_charge.primary_insurance_code == "No Primary Insurance") {
            toastr.error('', charge_messages.no_primary_insurance);
            in_valid_form_data = true;
            return in_valid_form_data;
        }
        // retreiving valid sequences corresponding to active diagnosis
        //add update dx code for case 
        if (this.diagnosis_child.get_update_diagnosis_code_list) {
            var diagnosis_code_info = this.diagnosis_child.get_update_diagnosis_code_list();
            var diagnosis_code_list = diagnosis_code_info.diagnosis_list;
            valid_dx_sequence = [];
            if (diagnosis_code_list.length > 0) {
                diagnosis_code_list.map((value, key) => {
                    if (value.is_active == true) {
                        valid_dx_sequence.push(value.sequence);
                    }
                });

            }
        }
        if (state_charge.pre_auth_id && state_charge.pre_auth_id > 0) {
            if (state_charge.pre_auth_id && state_charge.pre_auth_id > 0) {
                if (state_charge.selected_pre_auth_from_date != null && from_date > min_date) {
                    if (dos < from_date) {
                        valid_from_date = false;
                    }
                }
                if (state_charge.selected_pre_auth_thru_date != null && thru_date > min_date) {
                    if (dos > thru_date) {
                        valid_thru_date = false;
                    }
                }
                if (!valid_from_date || !valid_thru_date) {
                    var message = charge_messages.dos_out_of_range;
                    messages.push(message);
                    in_valid_form_data = true;

                }
            }
        }
        if (dos > current_date) {
            var message = charge_messages.future_dos;
            messages.push(message);
            in_valid_form_data = true;
        }
        if (state_charge.md_code == "") {
            var message = charge_messages.md_code_req;
            messages.push(message);
            in_valid_form_data = true;
        }
        //to check any charge exist or not
        if (state_charge.charges != null) {
            var sub_pre_auth_id = state_charge.pre_auth_id;
            var total_unit = 0;
            if (sub_pre_auth_id > 0) {
                let pre_auth_info = this.pre_auth_filter(_state.case_pre_auth_list, state_charge.pre_auth_id);
                if (pre_auth_info[0].is_units) {
                    if (pre_auth_info[0].visits_used == pre_auth_info[0].pre_auth_visits) {
                        var message = charge_messages.no_valid_auth_unit;
                        messages.push(message);
                        in_valid_form_data = true;
                    }
                }
            }
        }
        for (var i = 0; i < state_charge.charges.length; i++) {
            if (state_charge.charges[i].status == charge_status.NEW || state_charge.charges[i].status == charge_status.EDIT) {
                if (state_charge.charges[i].proc_mod1 == undefined || state_charge.charges[i].proc_mod1 == null || state_charge.charges[i].proc_mod1 == '') {
                    state_charge.charges[i].proc_mod1 = 0;
                }
                if (state_charge.charges[i].proc_mod2 == undefined || state_charge.charges[i].proc_mod2 == null || state_charge.charges[i].proc_mod2 == '') {
                    state_charge.charges[i].proc_mod2 = 0;
                }
                if (state_charge.charges[i].proc_mod3 == undefined || state_charge.charges[i].proc_mod3 == null || state_charge.charges[i].proc_mod3 == '') {
                    state_charge.charges[i].proc_mod3 = 0;
                }
                if (state_charge.charges[i].proc_code.procedure_code_id > 0) {
                    is_proc_code_found = true;
                    if (state_charge.charges[i].units == 0 || state_charge.charges[i].units == '') {
                        unit_req = false;
                    }
                }
                if (state_charge.charges[i].proc_code.procedure_code_id > 0) {
                    is_proc_code_found = true;
                    if (state_charge.charges[i].proc_code.std_chg_amt == '' || isNaN(state_charge.charges[i].proc_code.std_chg_amt)) {
                        state_charge.charges[i].proc_code.std_chg_amt = 0;
                        state_charge.charges[i].proc_code.total_amount = 0;
                    }
                }
            }
        }
        if (!is_proc_code_found && is_req_field_valid && unit_req) {
            var message = charge_messages.no_charges_to_save;
            messages.push(message);
            in_valid_form_data = true;
        }
        else if (!unit_req) {
            var message = charge_messages.unit_req;
            messages.push(message);
            in_valid_form_data = true;
        }
        var invalid_seq = [];
        state_charge.charges.map((val, key) => {
            if (val.status == charge_status.NEW || val.status == charge_status.EDIT) {
                if (val.proc_code.procedure_code_id > 0) {
                    row_dx_sequence.push(val.dx.split(','))
                    row_dx_sequence[0].map((seq_val, seq_index) => {
                        count = 0;
                        sequence = seq_val;
                        //to check duplicate dx no
                        if (!is_special_char(sequence) && sequence != '' && parseInt(sequence) > 0) {
                            row_dx_sequence[0].map((v, i) => {
                                if (sequence == v)
                                    count++;
                                if (count > 1) {
                                    if (!has_duplicate_dx) {
                                        var message = val.dx + charge_messages.invalid_dx_sequence;
                                        messages.push(message);
                                        in_valid_form_data = true;
                                        has_duplicate_dx = true;
                                    }
                                }
                            });

                        }
                        //to check dx code is empty
                        else if (sequence == "" && row_dx_sequence[0].length == 1 && !has_no_dx) {
                            var message = sequence + charge_messages.req_atleast_1dx;
                            messages.push(message);
                            in_valid_form_data = true;
                            has_no_dx = true;
                        }
                        //to check each dx code is valid or not
                        if (is_special_char(sequence) || valid_dx_sequence.indexOf(parseInt(sequence.toString())) == -1) {
                            if (sequence == "") {
                                if (!already_added && val.dx && row_dx_sequence[0].length > 1) {
                                    var message = val.dx + charge_messages.invalid_dx_no;
                                    messages.push(message);
                                    in_valid_form_data = true;
                                    already_added = true;
                                }
                            }
                            else {
                                var result = invalid_seq.filter((val, ind) => {
                                    return val == sequence;
                                });

                                if (result.length == 0) {
                                    var message = sequence + charge_messages.invalid_dx_no;
                                    messages.push(message);
                                    invalid_seq.push(sequence);
                                    in_valid_form_data = true;
                                }
                            }
                        }
                    });
                    row_dx_sequence = [];
                    count = 0;
                    has_duplicate_dx = false;
                }
            }
        })


        dx_error_msg = "";
        var messages_length = messages.length;
        if (messages_length > 0) {
            const toastr_options = this.show_html_content_toaster(get_multiple_errors(messages));
            toastr.error('', toastr_options);
        }
        return in_valid_form_data;
    }
    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }
    //filter of physician while selecting 
    pre_auth_filter = (data, id) => {
        var filtered = [];
        if (!(id == undefined || id == 0 || id == '')) {
            filtered = [];
            for (let row = 0; row < data.length; row++) {
                if (data[row].id == id) {
                    filtered.push(data[row]);
                }
            }
        }
        else {
            filtered = data;
        }
        return filtered;
    }
    validate_charge = () => {
        let state_charge = this.state.charge;
        let invalid_state = this.state.charge_form_invalid;
        let in_valid_form_data = false;
        if (state_charge.case_id <= 0) {
            invalid_state.charge.case = true;
            in_valid_form_data = true;
        }
        else {
            invalid_state.charge.case = false;
        }
        if (state_charge.formatted_date_of_service == null || state_charge.formatted_date_of_service == undefined) {
            invalid_state.charge.date_of_service = true;
            in_valid_form_data = true;
        }
        else {
            invalid_state.charge.date_of_service = false;
        }
        if (state_charge.place_of_service.id == '-999') {
            invalid_state.charge.location = true;
            in_valid_form_data = true;
        }
        else {
            invalid_state.charge.location = false;
        }
        if (state_charge.billing_provider.id == '-999') {
            invalid_state.charge.provider = true;
            in_valid_form_data = true;
        }
        else {
            invalid_state.charge.provider = false;
        }
        if (this._is_mounted) {
            this.setState({
                charge_form_invalid: invalid_state,
            })
        }
        var validate_form = {
            in_valid_form_data: in_valid_form_data,
            charge_data: invalid_state,
        }
        return validate_form;
    }
    on_close_confirmation = () => {

    }
    clear_quick_search = (type_of_search) => {
        var charge = this.state.charge;
        var charge_invalid = this.state.charge_form_invalid;
        if (type_of_search == enum_type_of_search.place_of_service) {
            charge_invalid.charge.location = true;
            charge.place_of_service.id = "-999";
            charge.pos_code = "";
            if (this._is_mounted) {
                this.setState({
                    location_quick_search_format: {
                        "title": '',
                        "label": '',
                        "pos_code": ''
                    },
                    charge: charge,
                    charge_form_invalid: charge_invalid,
                    calculate_charge_amount_params: { ...this.state.calculate_charge_amount_params, place_of_service_id: null }
                });
                this.recalculate_charge_amount();
            }
        }
        else if (type_of_search == enum_type_of_search.provider) {
            charge.billing_provider.id = "-999";
            charge.billing_provider_code = "";
            charge_invalid.charge.provider = true;
            if (this._is_mounted) {
                this.setState({
                    provider_quick_search_format: {
                        "title": '',
                        "label": '',
                        "bp_code": ''
                    },
                    charge: charge,
                    charge_form_invalid: charge_invalid,
                    calculate_charge_amount_params: { ...this.state.calculate_charge_amount_params, billing_provider_id: null }
                });
                this.recalculate_charge_amount();
            }
        }
        else if (type_of_search == enum_type_of_search.treating_provider) {
            charge.treating_provider.id = "-999";
            charge.treating_provider_code = "";
            if (this._is_mounted) {
                this.setState({
                    treating_provider_quick_search_format: {
                        "title": '',
                        "label": '',
                        "bp_code": ''
                    },
                    charge: charge
                });
            }
        }

    }
    validate_and_save_charge = async (skip_validation = false) => {

        var state_charge = this.state.charge;

        state_charge.patient_id = session_storage.get('active_patient'),
            state_charge.is_force_save = false;
        state_charge.is_kx_modifier_passed = false;
        state_charge.is_c_p_insurance_checked = false;
        state_charge.is_replacement_check_passed = false;
        state_charge.is_functional_reporting_code_missing_passed = false;
        state_charge.is_multiple_proc_code_for_same_revenue_passed = false;
        state_charge.is_functional_reporting_error_passed = false;

        //
        //convert to string fomat from moment object for saving
        state_charge.date_of_service = date_format_with_no_time(state_charge.formatted_date_of_service);
        state_charge.plan_of_care_date = date_format_with_no_time(state_charge.formatted_plan_of_care_date);
        state_charge.id = state_charge.case_id;
        if (this._is_mounted) {
            this.setState({
                form_submited: true
            })
        }
        if (this.charge_detail_child) {
            state_charge.charges = this.charge_detail_child.get_all_charge_data();
        }
        //

        if (!skip_validation && this._is_mounted) {
            this.setState({
                charge: state_charge
            });
        }

        if (this.diagnosis_child.get_update_diagnosis_code_list) {
            var diagnosis_code_info = this.diagnosis_child.get_update_diagnosis_code_list().diagnosis_list;
        }
        //
        let charge_form_validation = this.validate_charge();
        if (charge_form_validation.in_valid_form_data) {
            toastr.error('', charge_messages.required_all_fields);
            this.props.set_save_btn_disable(false);
            return;
        }
        if (skip_validation || !this.additional_charge_validate()) {
            if (this._is_mounted) {
                this.setState({
                    loading: true
                })
            }

            if (diagnosis_code_info && diagnosis_code_info.length > 0) {
                if (skip_validation) {
                    this.save_charge();
                }
                else {
                    //call addChargeDxCodeDetail API if dx is changed otherwise call only vmCharge.saveCharge()
                    if (this.diagnosis_child.add_update_diagnosis_code) {
                        var diagnosis_response = await this.diagnosis_child.add_update_diagnosis_code(state_charge.case_id);
                        this.save_charge();
                    }
                    //call savecharge only if dx is not changed
                    else {
                        this.save_charge();
                    }
                }
            }
            else {
                if (this._is_mounted) {
                    this.setState({
                        loading: false,
                        stop_payment: false,
                        stop_save: false,

                    });
                }
                this.props.set_save_btn_disable(false);
            }
        }
        else {
            if (this._is_mounted) {
                this.setState({
                    loading: false,
                    stop_payment: false,
                    stop_save: false,

                });
            }
            this.props.set_save_btn_disable(false);
        }
    }
    save_charge_and_payment = (state_charge) => {
        var payment_data = { ...this.state.payment };
        state_charge.charges_for_payment.map((value, key) => {
            payment_data.amount = payment_data.amount + value.total_amount
        });
        payment_data.deposit_date = this.state.selected_case_data.date_of_service
    }
    save_charge = () => {
        if (this._is_mounted) {
            this.setState({ loading: true });
        }

        let state_charge = this.state.charge;


        state_charge.charges.forEach(function (item, index) {
            item.proc_code.is_amt_enabled = item.proc_code.is_amt_enabled == "false" ? false : true;
            item.proc_code.is_unit_enabled = item.proc_code.is_unit_enabled == "false" ? false : true;
            item.proc_code.std_chg_amt = item.proc_code.std_chg_amt == "" ? 0 : item.proc_code.std_chg_amt;
            item.proc_code.is_active = toBoolean(item.proc_code.is_active);
        });
        state_charge.charge_date_of_service = date_format_with_no_time(state_charge.charge_date_of_service);
        state_charge.charge_plan_of_care_date = date_format_with_no_time(state_charge.charge_plan_of_care_date);

        add_charge(this.props.user_login_details.user_data.data.token_details.access_token, state_charge, this.state.charge.charge_id > 0).then(response => {
            //To store unique Proc Replacement Code List
            let one_to_one_replacement_list = [];
            let is_poc_required = false;
            var has_one_to_one_replacement = false;
            var replacement_message = '';

            var has_one_to_many_replacement = false;
            let proc_replacement_list = [];

            if (response.data.data) {
                for (var i = 0; i < response.data.data.length; i++) {
                    var charge = response.data.data[i];
                    //If ReplacementType is OneToOne 
                    if (charge.replacement_type == 1) {
                        has_one_to_one_replacement = true;
                        var replacementCode =
                        {
                            invalid_proc_code: charge.invalid_proc_code
                        }
                        //Adding unique Proc Code in List
                        var invalidProcIndex = one_to_one_replacement_list.map(function (replacementCode) {
                            return replacementCode.invalid_proc_code;
                        }).indexOf(replacementCode.invalid_proc_code);
                        if (invalidProcIndex == -1) {
                            one_to_one_replacement_list.push(replacementCode);
                            replacement_message += 'Code ' + charge.invalid_proc_code + ' is not valid for this payer. This code has been replaced with valid code ' + charge.proc_code.proc_code + "<br/>";
                        }
                    }
                    else if (charge.replacement_type == 2) {
                        has_one_to_many_replacement = true;
                        var replacement_code =
                        {
                            invalid_proc_code: charge.proc_code.proc_code,
                            valid_proc_codes: charge.proc_replacement_list
                        }
                        var codeFound = false;
                        for (var j = 0; j < proc_replacement_list.length; j++) {
                            if (proc_replacement_list[j].invalid_proc_code == replacement_code.valid_proc_codes) {
                                codeFound = true;
                            }
                        }
                        if (!codeFound) {
                            proc_replacement_list.push(replacement_code);
                        }
                    }

                    if (charge.new_proc_type != 5) {
                        charge.proc_code.is_unit_enabled = true;
                    }
                    else {
                        charge.proc_code.is_unit_enabled = false;
                    }

                    response.data.data[i] = charge;
                }
                state_charge.charges = response.data.data;
                if (this._is_mounted) {
                    this.setState({
                        proc_replacement_list: proc_replacement_list,
                        charge: state_charge,
                        update_charge_details: true

                    })
                }
            }
            this.charge_detail_child.set_charge_detail_data(state_charge.charges);
            //one to one ::code replacement message for one to one replacement
            //display info for one to one replacement
            if (has_one_to_one_replacement) {
                const toastr_options = this.show_html_content_toaster(replacement_message);
                toastr.warning('', toastr_options);
            }
            //when no message found i.e. success
            if (response.data.data && response.data.messages == null && response.data.messages == undefined) {
                //sucessfull
                toastr.success('', charge_messages.added_successfully);
                if (this.props.save_response) {
                    this.props.save_response();
                }

                state_charge.is_replacement_check_passed = false;
                let new_payment_code = [];
                let payment_method_code = this.props.user_login_details.payment_methods;
                var payment_data = { ...this.state.payment };
                if (this.state.is_full_payment) {
                    state_charge.charges_for_payment = [...this.state.charge.charges];
                    if (state_charge.charges_for_payment.length > 0) {
                        state_charge.charges_for_payment.map((value, key) => {
                            payment_data.amount = Number(payment_data.amount) + Number(value.total_amount)
                        });
                    }
                    const accrual_flag = local_storage.get("auth_data").accrual_accounting || false;
                    const open_month = local_storage.get("auth_data").open_month || null;
                    var min_date = null;
                    if (accrual_flag) {
                        min_date = new Date(open_month);
                    }
                    payment_data.min_date = min_date;
                    payment_data.payment_money_type = payment_method_code && payment_method_code.length ? payment_method_code[1].value : null;
                    payment_data.deposit_date = date_format_with_no_time(this.state.charge.charge_date_of_service);
                    new_payment_code = this.filter_payment_code_list();
                    new_payment_code.unshift({ key: '', value: "0", text: "-- Please Select a Payment Code --" });
                }
                //update patient header
                this.props.update_patient_header_info(true, this.state.charge.case_id, true);
                //
                state_charge.date_of_service = null;
                state_charge.formatted_date_of_service = null;
                state_charge.charge_date_of_service = null;
                this.reset_after_save(this.state.charge.case_id);
                this.props.set_save_btn_disable(false);
                if (this._is_mounted) {
                    this.setState({
                        loading: false,
                        stop_save: false,
                        stop_payment: false,
                        charge: state_charge,
                        show_payment_full_modal: this.state.is_full_payment,
                        payment_code_list: new_payment_code,
                        payment: payment_data
                    });
                }

            }
            //when messages found i.e. error or warning or replacement popup
            else if (response.data.messages != null && response.data.messages != undefined && response.data.messages.length > 0) {
                //error

                if (response.data.messages[0].code == '50000') {
                    let error = response.data.messages[0].message.split(/\|/);
                    //get_all_error(response.data);
                    for (var i = 0; i < error.length; i++) {
                        toastr.error('', error[i]);
                        if (error[i] && error[i].trim() == "Plan of Care Date is Required.") {
                            let invalid_state = this.state.charge_form_invalid;
                            invalid_state.charge.plan_of_care = true;
                            if (this._is_mounted) {
                                this.setState({
                                    is_poc_required: true,
                                    charge_form_invalid: invalid_state
                                });
                            }
                        }
                    }
                }
                //warning : 50002 is for warning and 50005 is for replacement warning
                else if (response.data.messages[0].code == '50002' || response.data.messages[0].code == '50005') {
                    let error_messages = get_multiple_errors(response.data.messages[0].message);
                    var errorMessages = [];
                    var show_warning_popup = false;
                    var show_proc_replacement_warning_popup = false;
                    var show_kx_modifier_popup = false;
                    var is_replacement = this.state.is_replacement_checked;
                    let error = response.data.messages[0].message.split(/\|/);
                    for (var i = 0; i < error.length; i++) {
                        if (error[i]) {
                            var errorMessage = {
                                val: error[i]
                            };
                            errorMessages.push(errorMessage);
                        }
                    }
                    if (response.data.messages[0].code == '50002') {
                        show_warning_popup = true
                        is_replacement = false;
                    }
                    else if (has_one_to_many_replacement) {
                    }
                    else {
                        state_charge.is_replacement_check_passed = false;
                        is_replacement = true;
                        show_proc_replacement_warning_popup = true;
                    }
                    if (this._is_mounted) {
                        this.setState({
                            is_replacement_checked: is_replacement,
                            charge_error_messages: errorMessages,
                            show_warning_popup: show_warning_popup,
                            show_proc_replacement_warning_popup: show_proc_replacement_warning_popup,
                            charge: state_charge,
                        });
                    }
                }
                //Kx Modifier    
                else if (response.data.messages[0].code == '50006') {
                    state_charge.is_replacement_check_passed = true;
                    if (this._is_mounted) {
                        this.setState({
                            is_kx_modifier_replacement_popup: false,
                            show_kx_modifier_popup: true,
                            charge: state_charge
                        });
                    }
                }
                //CPInsurance Check Warning
                else if (response.data.messages[0].code == '50007') {
                    state_charge.is_replacement_check_passed = true;
                    if (this._is_mounted) {
                        this.setState({
                            show_cp_insurance_check: true,
                            charge: state_charge
                        });
                    }
                }

                else if (response.data.messages[0].code == '50011') {
                    state_charge.is7th_index_check_passed = false;
                    if (this._is_mounted) {
                        this.setState({
                            show_has_dx_7th_digit_popup: true,
                            dx_7th_digit_message: response.data.messages[0].message,
                            charge: state_charge
                        });
                    }
                }
                else if (response.data.messages[0].code == '50012') {
                    state_charge.is_functional_reporting_code_missing_passed = false;
                    if (this._is_mounted) {
                        this.setState({
                            show_missing_functional_reporting_popup: true,
                            missing_functional_reporting_code_message: response.data.messages[0].message,
                            charge: state_charge
                        });
                    }
                }
                else if (response.data.messages[0].code == '50013') {
                    state_charge.is_functional_reporting_error_passed = false;
                    if (this._is_mounted) {
                        this.setState({
                            show_functional_reporting_error_popup: true,
                            functional_reporting_error_message: response.data.messages[0].message,
                            charge: state_charge
                        });
                    }
                }
                //check if multiple proc for save revenue center 
                else if (response.data.messages[0].code == '50016') {
                    state_charge.is_multiple_proc_code_for_same_revenue_passed = false;
                    if (this._is_mounted) {
                        this.setState({
                            show_multiple_procode_for_same_revenue_popup: true,
                            multiple_procode_for_same_revenue_message: response.data.messages[0].message,
                            charge: state_charge
                        });
                    }
                }

                else if (response.data.messages[0].code == '50014') {
                    if (this._is_mounted) {
                        this.setState({
                            show_invalid_case_error_popup: true,
                            invalid_case_messge: response.data.messages[0].message
                        });
                    }
                }
                else if (response.data.messages[0].code == global_constants.charge_contains_payment_exception.code) {
                    this.show_modal_popup(global_constants.charge_contains_payment_exception.title, global_constants.charge_contains_payment_exception.message, "OK", this.on_close_bummepd_charge_popup, false);
                }
                //replacement popup
                if (has_one_to_many_replacement && response.data.messages[0].code != '50000'
                    && response.data.messages[0].code != '50002' && response.data.messages[0].code != '50007') {
                    state_charge.is_replacement_check_passed = true;
                    if (this._is_mounted) {
                        this.setState({
                            charge: state_charge,
                            show_proc_replacement_popup: true
                        });
                    }
                }
                if (this._is_mounted) {
                    this.setState({ loading: false, stop_save: false, stop_payment: false })
                }
                this.props.set_save_btn_disable(false);
            }
            //



        }, (error) => {
            if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length > 0) {
                let charge_exception = error.response.data.messages.filter((item) => {
                    return item.code === global_constants.charge_exception.code || item.code === global_constants.charge_contains_payment_exception.code
                })
                if (charge_exception && charge_exception.length > 0) {
                    this.show_modal_popup('Confirm', global_constants.charge_exception.message, 'Refresh', this.refresh_bummepd_charge_modal);
                } else {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error('', toastr_options);
                }
            }

            if (this._is_mounted) {
                this.setState({
                    loading: false,
                })
            }
            this.props.set_save_btn_disable(false);
        })
    }
    on_save_payment_charge = async () => {
        this.props.set_save_btn_disable(true);
        if (this._is_mounted) {
            this.setState({
                is_full_payment: true
            });
        }
        this.validate_and_save_charge();
    }
    on_save_charge_click = async () => {
        this.props.set_save_btn_disable(true);
        if (this._is_mounted) {
            this.setState({
                is_full_payment: false
            });
        }
        this.validate_and_save_charge();
    }

    // function / Method to handle the state change of the date controls used
    handle_calendar_change = (dt, date_type) => {
        let m = date_format_with_no_time(dt);
        var charge_info = this.state.charge;
        var charge_invalid = this.state.charge_form_invalid;

        if (date_type == enum_date_type.date_of_service) {
            if (m != null && m.isValid()) { charge_invalid.charge.date_of_service = false; }
            else { charge_invalid.charge.date_of_service = true; }

            charge_info.formatted_date_of_service = m;
            charge_info.charge_date_of_service = m;
            this.check_fun_reporting();

            if (this.props.ff_sumaj_B30566_expected_rate_multiple_fee_schedules) {
                let last_date_of_service = this.state.last_date_of_service;
                if (!moment(last_date_of_service).isSame(m, 'day')) {
                    this.setState({
                        calculate_charge_amount_params: {
                            ...this.state.calculate_charge_amount_params,
                            date_of_service: m
                        },
                        last_date_of_service: m
                    });
                    this.recalculate_charge_amount();
                }
            }
        }
        else if (date_type == enum_date_type.plan_of_care) {
            if (m != null && m.isValid()) { charge_invalid.charge.plan_of_care = false; }
            
            charge_info.formatted_plan_of_care_date = m;
            charge_info.charge_plan_of_care_date = m;
        }

        if (this._is_mounted) {
            this.setState({
                charge: charge_info,
                charge_form_invalid: charge_invalid,

            });
        }
    }

    date_picker_focus = () => {
        var elmnt = document.getElementById("scrollable-content-area");
        if (elmnt) {
            elmnt.scrollTop = 0;
        }
    }

    get_old_dx_list = () => {
        if (this.diagnosis_child) {
            if (this.diagnosis_child.get_update_diagnosis_code_list) {
                var diagnosis_code_info = this.diagnosis_child.get_update_diagnosis_code_list();
                var diagnosis_code_list = diagnosis_code_info.diagnosis_list;
                if (this._is_mounted && diagnosis_code_info.diagnosis_list) {
                    this.setState({ old_dx_list: diagnosis_code_info.diagnosis_list })
                }
            }
        }
    }

    update_dx_List = () => {
        this.get_old_dx_list();
    }

    close_warning_pop_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_warning_popup: false,
            });
        }
    }
    save_warning_charges = () => {
        let charge_state = this.state.charge;
        if (this.state.is_replacement_checked) {
            charge_state.is_replacement_check_passed = true;
        }
        else {
            charge_state.is_force_save = true;
            charge_state.is_replacement_check_passed = false;
        }

        if (this._is_mounted) {
            this.setState({
                show_warning_popup: false,
                charge: charge_state
            });
        }
        this.save_charge();

    }

    close_replacement_warning_popup_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_proc_replacement_warning_popup: false,
            });
        }
    }

    proc_replacement_warning_popup_ok = () => {
        if (this._is_mounted) {
            this.setState({
                show_proc_replacement_warning_popup: false,
            });
        }
    }

    close_kx_modifier_popup_modal = () => {
        let state_charge = this.state.charge;
        state_charge.is_kx_modifier_passed = true;
        let charges = state_charge.charges;
        charges.map((val, key) => {
            if (val.has_kx_modifier && val.proc_code.procedure_code_id != 0) {
                if (val.proc_mod1 == 0 && val.proc_mod2 != 0) {
                    val.proc_mod1 = val.proc_mod2;
                    val.proc_mod2 = 0;
                }
            }
        });
        state_charge.charges = charges;
        if (this._is_mounted) {
            this.setState({
                show_kx_modifier_popup: false,
                show_kx_modifier_popup1: false,
                charge: state_charge
            }, (() => { this.save_charge(); }));
        }
    }

    save_kx_modifier_warning = () => {
        let is_modifier_differ = false;
        let state_charge = this.state.charge;
        let charges = state_charge.charges;
        let kx_modifier_id = '';
        if (this.charge_detail_child) {
            kx_modifier_id = this.charge_detail_child._kx_modifier_id;
        }

        if (this._is_mounted) {
            this.setState({
                show_kx_modifier_popup: false,
                show_kx_modifier_popup1: false,
            }, () => {

                if (!this.state.is_kx_modifier_replacement_popup) {
                    charges.map((val, key) => {
                        if (val.has_kx_modifier && val.proc_mod1 != kx_modifier_id && val.proc_mod2 != kx_modifier_id && val.proc_mod1 != 0 && val.proc_mod2 != 0) {
                            is_modifier_differ = true;
                        }
                    });

                    if (is_modifier_differ) {
                        if (this._is_mounted) {
                            this.setState({
                                show_kx_modifier_popup1: true,
                                is_kx_modifier_replacement_popup: true,
                                is_kx_modifier_replacement_change_popup: true
                            })
                        }
                    }
                    else {
                        let charges = state_charge.charges;
                        charges.map((val, key) => {
                            if (val.has_kx_modifier && val.proc_code.procedure_code_id != 0 && val.proc_code.procedure_code_id != null && val.proc_code.procedure_code_id != undefined) {
                                if (val.proc_mod1 != 0 && val.proc_mod2 == 0) {
                                    val.proc_mod2 = kx_modifier_id;
                                }
                                else if (val.proc_mod1 == 0 && val.proc_mod2 != 0) {
                                    val.proc_mod1 = kx_modifier_id;
                                }
                                else if (val.proc_mod1 == 0 && val.proc_mod2 == 0) {
                                    val.proc_mod1 = kx_modifier_id;
                                }
                            }
                        });
                        state_charge.is_kx_modifier_passed = true;
                        state_charge.charges = charges;
                        if (this._is_mounted) {
                            this.setState({
                                show_kx_modifier_popup: false,
                                is_kx_modifier_replacement_popup: true,
                                is_kx_modifier_replacement_change_popup: true,
                                charge: state_charge
                            }, (() => { this.save_charge(); }))
                        }

                    }
                }
                else if (this.state.is_kx_modifier_replacement_change_popup) {
                    //replacement code
                    let charges = state_charge.charges;
                    charges.map((val, key) => {
                        if (val.has_kx_modifier && val.proc_mod1 != kx_modifier_id && val.proc_mod2 != kx_modifier_id && val.proc_mod1 != 0 && val.proc_mod2 != 0) {
                            if (val.proc_mod1 == 0) {
                                val.proc_mod1 = kx_modifier_id;
                            }
                            else {
                                val.procMod2 = kx_modifier_id;
                            }
                        }
                    });
                    state_charge.is_kx_modifier_passed = true;
                    state_charge.charges = charges;
                    if (this._is_mounted) {
                        this.setState({
                            show_kx_modifier_popup: false,
                            charge: state_charge
                        }, (() => { this.save_charge(); }))
                    }

                }
                else {
                    state_charge.is_kx_modifier_passed = true;
                    if (this._is_mounted) {
                        this.setState({
                            show_kx_modifier_popup: false,
                            charge: state_charge
                        }, (() => { this.save_charge(); }))
                    }
                }
            });
        }
    }

    close_cp_insurance_check_popup_modal = () => {
        let state_charge = this.state.charge;
        state_charge.is_c_p_insurance_checked = true;
        state_charge.donot_count_visit = true;
        if (this._is_mounted) {
            this.setState({
                show_cp_insurance_check: false,
                charge: state_charge
            }, (() => { this.save_charge(); }));
        }
    }

    save_cp_insurance_check = () => {
        let state_charge = this.state.charge;
        state_charge.is_c_p_insurance_checked = true;
        state_charge.donot_count_visit = false;
        if (this._is_mounted) {
            this.setState({
                show_cp_insurance_check: false,
                charge: state_charge
            }, (() => { this.save_charge(); }));
        }
    }

    close_has_dx_7th_digit_popup_modal = () => {
        let state_charge = this.state.charge;
        state_charge.is7th_index_check_passed = false;
        if (this._is_mounted) {
            this.setState({
                show_has_dx_7th_digit_popup: false,
                charge: state_charge
            });
        }
    }
    save_has_dx_7th_digit_popup = () => {
        let state_charge = this.state.charge;
        state_charge.is7th_index_check_passed = true;
        if (this._is_mounted) {
            this.setState({
                show_has_dx_7th_digit_popup: false,
                charge: state_charge
            }, (() => { this.save_charge(); }));
        }
    }
    close_missing_functional_reporting_modal = () => {
        let state_charge = this.state.charge;
        state_charge.is_functional_reporting_code_missing_passed = false;
        if (this._is_mounted) {
            this.setState({
                show_missing_functional_reporting_popup: false,
                charge: state_charge,
                missing_functional_reporting_code_message: ''
            });
        }
    }
    save_missing_functional_reporting_modal = () => {
        let state_charge = this.state.charge;
        state_charge.is_functional_reporting_code_missing_passed = true;
        if (this._is_mounted) {
            this.setState({
                show_missing_functional_reporting_popup: false,
                charge: state_charge,
                missing_functional_reporting_code_message: ''
            }, (() => { this.save_charge(); }));
        }
    }
    close_functional_reporting_error_modal = () => {
        let state_charge = this.state.charge;
        state_charge.is_functional_reporting_error_passed = false;
        if (this._is_mounted) {
            this.setState({
                show_functional_reporting_error_popup: false,
                functional_reporting_error_message: '',
                charge: state_charge
            });
        }
    }
    save_functional_reporting_error_modal = () => {
        let state_charge = this.state.charge;
        state_charge.is_functional_reporting_error_passed = true;

        if (this._is_mounted) {
            this.setState({
                show_functional_reporting_error_popup: false,
                functional_reporting_error_message: '',
                charge: state_charge
            }, (() => { this.save_charge(); }));
        }
    }
    close_invalid_case_error_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_invalid_case_error_popup: false,
                invalid_case_messge: ''
            });
        }
    }
    save_invalid_case_error_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_invalid_case_error_popup: false,
                invalid_case_messge: ''
            }, (() => {
                this.reset_after_save(this.state.charge.case_id)
            }));
        }
    }

    close_proc_replacement_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_proc_replacement_popup: false,
            });
        }
    }

    save_proc_replacement_modal = () => {
        let state_charge = this.state.charge;
        let is_proc_type_mismatch = false;
        state_charge.is_force_save = true;
        state_charge.is_replacement_check_passed = true;
        var charges = [...state_charge.charges];
        charges.map((charge, key) => {
            if (charge.status == charge_status.NEW || charge.status == charge_status.EDIT) {
                var proc_replace_list = this.state.proc_replacement_list;
                var position = proc_replace_list.map(function (search_proc_code) {
                    return search_proc_code.invalid_proc_code.toString();
                }).indexOf(charge.proc_code.proc_code.toString());


                if (position != -1) {
                    var replacement_code = proc_replace_list[position];
                    var element_position = replacement_code.valid_proc_codes.map(function (search_proc_code) {
                        return search_proc_code.procedure_code_id.toString();
                    }).indexOf(replacement_code.selected_proc_code_id.toString());



                    charge.proc_code = replacement_code.valid_proc_codes[element_position];
                    if (charge.original_proc_type != charge.proc_code.proc_type) {
                        is_proc_type_mismatch = true;
                    }
                    charge.total_amount = charge.units * charge.proc_code.std_chg_amt;
                    charge.new_proc_type = charge.proc_code.proc_type;

                    if (charge.new_proc_type != 5) {
                        charge.proc_code.is_unit_enabled = true;
                    }
                    else {
                        charge.proc_code.is_unit_enabled = false;
                    }

                }

                if (charge.proc_code.procedure_code_id > 0) {
                    // angular.element('#autosearch_ProcedureCode' + index + ' input').val(charge.procCode.procCode + ' - ' + (charge.procCode.procDescription1 == null && charge.procCode.procDescription1 == undefined ? '' : charge.procCode.procDescription1));
                }
            }
        })
        //code to merge similar charges post replacement
        let new_charges = [];
        let is_merged = false;
        let charge_index = -1;
        let error_messages = [];
        charges.map((charge, key) => {
            if (new_charges.length > 0 && (charge.status == charge_status.NEW || charge.status == charge_status.EDIT) && charge.proc_code.procedure_code_id != 0) {

                charge_index = new_charges.map(function (new_charge) {
                    return (new_charge.status == charge_status.NEW || new_charge.status == charge_status.EDIT) ? new_charge.proc_code.proc_code : '';
                }).indexOf(charge.proc_code.proc_code);

                if (charge_index != -1) {
                    if (new_charges[charge_index].status == charge_status.NEW || new_charges[charge_index].status == charge_status.EDIT) {
                        is_merged = true;
                        new_charges[charge_index].units = new_charges[charge_index].units + charge.units;
                        new_charges[charge_index].total_amount = charge.proc_code.std_chg_amt * new_charges[charge_index].units;
                        new_charges[charge_index].new_proc_type = charge.proc_code.proc_type;
                    }
                    else
                        new_charges.push(charge);
                }
                else {
                    new_charges.push(charge);
                }
            }
            else {
                new_charges.push(charge);
            }
        })

        if (is_merged) {
            state_charge.charges = new_charges;
            if (this._is_mounted) {
                this.setState({
                    show_proc_replacement_popup: false,
                    charge: state_charge,
                })
            }
        } else {
            state_charge.charges = charges;
            if (this._is_mounted) {
                this.setState({
                    show_proc_replacement_popup: false,
                    charge: state_charge,
                }, this.charge_detail_child.set_charge_detail_data(state_charge.charges))
            }
        }

        //code to show proc type mismatch
        if (is_merged || is_proc_type_mismatch) {
            var message = '';
            if (is_proc_type_mismatch && is_merged) {
                message = charge_messages.merge_charge_data_with_proc_type_mismatch;
            }
            else if (is_merged && !is_proc_type_mismatch) {
                message = charge_messages.merge_charge_data;
            }
            else {
                message = charge_messages.proc_type_mismatch;
            }
            var error_message = {
                val: message
            };

            error_messages.push(error_message);
            if (this._is_mounted) {
                this.setState({
                    show_proc_replacement_warning_popup: true,
                    charge: state_charge,
                    charge_error_messages: error_messages
                }, () => {
                    if (is_merged) {
                        this.charge_detail_child.set_charge_detail_data(state_charge.charges);
                    }
                })
            }
        }
        if (!is_merged) {
            this.save_charge();
        }
    }

    update_data = (source) => {
        var target = [];

        var source_length = source.length;

        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];

                target.push({
                    "text": item.proc_code,
                    "value": item.procedure_code_id,
                    "key": item.procedure_code_id
                })
            }
        }

        return target;

    }
    invalid_proc_replacement_change = (value, row, index) => {
        var data = this.state.proc_replacement_list;
        data[index].selected_proc_code_id = value;
        if (this._isMounted) {
            this.setState({
                proc_replacement_list: data
            });
        }
    }

    close_payment_full_moda = () => {
        if (this._is_mounted) {
            this.setState({
                show_payment_full_modal: false,
                payment: this.reset_payment(),
                payment_full_submitted: false
            });
        }
    }

    save_payment_full_payment_focus_event = (e, id = '') => {
        if (!e.shiftKey && (e.keyCode == 9 || e.which == 9)) {
            let el: HTMLElement = document.querySelector('#PaymentinFull .close');
            if (el) {
                el.focus();
                e.preventDefault();
            }
        }
    }

    save_payment_full_moda = async (payment_data) => {
        //  var payment_data = { ...this.state.payment }
        var charge_data = { ...this.state.charge }
        payment_data.patient_id = this.state.patient_id;
        payment_data.billing_provider.id = this.state.selected_case_data.billing_provider.id;
        payment_data.place_of_service.id = this.state.selected_case_data.place_of_service.id;
        payment_data.deposit_date = date_format_with_no_time(payment_data.deposit_date);

        if (payment_data.payment_money_type == money_type.creditCard || payment_data.payment_money_type == money_type.debitCard || payment_data.payment_money_type == money_type.HSA) {
            payment_data.credit_card_auth_num = payment_data.cc_num;
        }
        else {
            payment_data.check_num = payment_data.cc_num;
        }
        if (!this.validate_payment_data(payment_data)) {
            if (this._is_mounted) {
                this.setState({
                    payment: payment_data,
                    payment_full_submitted: true
                });
            }
            return;
        } else {
            payment_data.deposit_date = date_format_with_no_time(payment_data.deposit_date);
            var payment_details = {
                credits: [],
                is_from_charge: true,
                is_force_save: true,
                is_replacement_check_passed: true,
                current_date: date_format_with_no_time(new Date())
            }

            charge_data.charges_for_payment.map((value, index) => {
                payment_data.charge_id = value.charge_id;
                payment_data.amount = value.total_amount;
                payment_data.original_amount = value.total_amount;
                payment_details.credits.push(Object.assign({}, payment_data));
            });
            if (this._is_mounted) {
                this.setState({
                    payment_loading: true
                });
            }

            if (this.props.magnusPlat604AuditLogCreateTheAddUpdateDeleteControllersForPaymentActions) {
                await this.saveAddCharge(payment_details);
            }
            else {
                await this.saveCharge(payment_details);
            }
        }
    }

    saveCharge = async (payment_details) => {
        await saveChargePayment(payment_details, this.props.user_login_details.user_data.data.token_details.access_token)
            .then((response) => {
                this.responseSaveCharge(response);
            }, (error) => {
                this.errorSaveCharge(error);
            })
    }

    saveAddCharge = async (payment_details) => {
        await addChargePayment(payment_details, this.props.user_login_details.user_data.data.token_details.access_token)
            .then((response) => {
                this.responseSaveCharge(response);
            }, (error) => {
                this.errorSaveCharge(error);
            })
    }

    responseSaveCharge = (response) => {
        if (response.data.data != 0) {
            if (this._is_mounted) {
                this.setState({
                    payment_loading: false,
                    show_payment_full_modal: false,
                    payment_full_submitted: false,
                    payment: this.reset_payment()
                });
            }
            toastr.success('', charge_messages.payment_added);
        } else {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
            toastr.error('', toastr_options);
            if (this._is_mounted) {
                this.setState({
                    payment_loading: false,
                });
            }
        }
    }

    errorSaveCharge = (error) => {
        if (this._is_mounted) {
            this.setState({
                payment_loading: false,
                show_payment_full_modal: false,
                payment_full_submitted: false
            });
        }
        if (error.response.data) {
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toastr.error('', toastr_options);
        }
    }

    validate_payment_data = (payment_data) => {
        var isValidData = true;
        var is_valid_deposite = true;
        const accrual_flag = local_storage.get("auth_data").accrual_accounting || false;
        const open_month = local_storage.get("auth_data").open_month || null;
        var minDate = null;
        if (accrual_flag) {
            minDate = new Date(open_month);
        }
        if (payment_data.deposit_date == undefined || payment_data.deposit_date == null || payment_data.deposit_date == '') {
            isValidData = false;
            document.getElementById("payment_deposite_date").focus();
        }
        else if (payment_data.deposit_date < minDate) {
            is_valid_deposite = false;
            isValidData = false;
            document.getElementById("payment_deposite_date").focus();
        }
        else if (payment_data.payment_code.payment_id == undefined || payment_data.payment_code.payment_id <= 0) {
            isValidData = false;
            document.getElementById("payment_code").focus();
        }
        else if ((payment_data.amount == undefined || payment_data.amount < 0) && payment_data.patient_credit_type_id != patientCreditType.patientrefund) {
            isValidData = false;
            document.getElementById("payment_code").focus();
        }
        else if (payment_data.payment_money_type == money_type.check && (payment_data.cc_num == undefined || payment_data.cc_num == '')) {
            isValidData = false;
            document.getElementById("payment_check_cc_number").focus();
        }
        else if ((payment_data.payment_money_type == money_type.creditCard || payment_data.payment_money_type == money_type.debitCard || payment_data.payment_money_type == money_type.HSA) && (payment_data.cc_num == undefined || payment_data.cc_num == '')) {
            isValidData = false;
            document.getElementById("payment_check_cc_number").focus();
        }
        //to check required validation
        if (!is_valid_deposite) {
            toastr.error('', charge_messages.month_close);
        }
        else if (!isValidData) {
            toastr.error('', charge_messages.required_all_fields);
        }
        return isValidData;
    }

    filter_payment_code_list = () => {
        const payment_code_list = this.props.user_login_details.master_data.data.payment_code;
        const new_payment_code = payment_code_list.map((val, index) => {
            return { key: index, value: val.payment_id, text: val.payment_desc, data: val }
        });
        return new_payment_code;
    }

    on_dropdown_payment_code = (value) => {
        const selected = this.state.payment_code_list.filter(data => {
            return data.value == value
        });
        if (this._is_mounted) {
            if (selected && selected.length > 0 && selected[0].data != undefined) {
                const credit_data_updated = { ...this.state.payment, payment_code: selected[0].data };
                this.setState(prevState => ({
                    payment: credit_data_updated
                }))
            } else {
                const payment_code = {
                    payment_id: ''
                }
                const credit_data_updated = {
                    ...this.state.payment, payment_code: payment_code
                };
                this.setState(prevState => ({
                    payment: credit_data_updated
                }))
            }
        }
    }

    payment_deposit_date_change = (dt) => {
        let m = moment.parseZone(dt);
        var payment_data = this.state.payment;
        if (m.isValid()) {
            payment_data.deposit_date = m;
            payment_data.formatted_deposit_date = m;
        } else {
            payment_data.deposit_date = null;
            payment_data.formatted_deposit_date = null;
        }
        if (this._is_mounted) {
            this.setState({
                payment: payment_data
            });
        }
    }

    on_payment_value_change = (value, input_name) => {
        var check_num = true;
        var error_data = { ...this.state.payment_error };
        var payment_state_data = { ...this.state.payment, [input_name]: value };
        if (input_name === 'payment_money_type') {
            if (value === 1) {
                check_num = false;
            }
        } else if (input_name === 'amount' && value != '') {
            error_data.amount = false;
        }
        if (this._is_mounted) {
            this.setState(prevState => ({
                payment: payment_state_data,
                payment_error: error_data,
            }));
        }
    }

    reset_payment = () => {
        var payment = {
            min_date: null,
            deposit_date: null,
            formatted_deposit_date: null,
            credit_type_id: '',
            payment_code: {
                payment_id: ''
            },
            amount: '',
            payment_money_type: 3,
            check_credit_card: '',
            batch: '',
            check_num: '',
            cc_num: '',
            comment: '',
            billing_provider: {
                id: ''
            },
            place_of_service: {
                id: ''
            },
            patient_id: '',
            credit_card_auth_num: '',
            original_amount: '',
            charge_id: '',
            patient_credit_type: {
                credit_type_id: 1,
                credit_type: 'Copay'
            }

        }
        return payment;
    }
    //check functional reporting
    check_fun_reporting = () => {
        var dt = new Date(default_values.comp_date);
        let msg = ''
        if (this.state.charge.is_functional_reporting && this.state.charge.charge_date_of_service != '' && new Date(this.state.charge.charge_date_of_service) >= dt) {
            msg = charge_messages.functional_reporting;
        }
        if (this._is_mounted) {
            this.setState({
                functional_reporting: msg
            })

        }
    }

    // UI Control Section

    on_close_multiple_procode_for_same_revenue_popup = () => {
        let state_charge = this.state.charge;
        state_charge.is_multiple_proc_code_for_same_revenue_passed = false;

        if (this._is_mounted) {
            this.setState({
                show_multiple_procode_for_same_revenue_popup: false,
                show_multiple_procode_for_same_revenue_confirm_popup: true,
                multiple_procode_for_same_revenue_message: '',
                charge: state_charge
            });
        }
    }

    save_multiple_procode_for_same_revenue_modal = () => {
        let state_charge = this.state.charge;
        state_charge.is_multiple_proc_code_for_same_revenue_passed = true;

        if (this._is_mounted) {
            this.setState({
                show_multiple_procode_for_same_revenue_popup: false,
                multiple_procode_for_same_revenue_message: '',
                charge: state_charge
            }, (() => { this.save_charge(); }));
        }
    }

    on_close_multiple_procode_for_same_revenue_confirm = () => {
        if (this._is_mounted) {
            this.setState({
                show_multiple_procode_for_same_revenue_confirm_popup: false
            });
        }
    }

    handle_change = (item) => {
        this.charge_case_info_change({
            name: "case",
            value: item ? item.value : '0'
        }, null);
    };

    recalculate_charge_amount = () => {
        if (this.props.ff_sumaj_B30566_expected_rate_multiple_fee_schedules && this.charge_detail_child) {
            this.charge_detail_child.recalculate_charge_amount();
        }
    }

    refresh_bummepd_charge_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            }, () => {
                if (this.props.charge_review_or_audit) {
                    if (this.props.save_response) {
                        this.props.save_response();
                    }
                }
                else if (this.state.charge.charge_id > 0) {
                    this.props.history.goBack();
                }
            });
        }
    }
    on_close_bummepd_charge_popup = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            });
        }
    }

    show_modal_popup(title: string, message: string, okBtnText: string, action: () => any, showCancel: boolean = true) {
        if (this._is_mounted) {
            this.setState({
                loading: false,
                show_bummepd_charge_message_popup: true,
                charge_popup_message: message,
                charge_popup_title: title,
                charge_popup_ok_btn_text: okBtnText,
                charge_popup_show_cancel: showCancel,
                charge_popup_action: action
            })
        }
    }

    contains_failure_message(response: R6ResponseData, code: number): [boolean, string] {

        if (response.status == R6ResponseStatus.failure) {
            let messages = response.messages.filter((item) => item.code === code)
            if (messages && messages.length > 0) return [true, messages[0].message];
        }

        return [false, ""];
    }

    render() {
        var style_margin_diagnosis = { marginTop: '8px', marginBottom: '20px' };
        return (
            <React.Fragment>
                {this.state.save_confirmation_box ?
                    <ConfirmationComponent message={charge_messages.save_confirmation}
                        title='Confirm' show_modal={this.state.save_confirmation_box} onCloseModal={this.on_close_confirmation} on_force_save_click={(e) => { this.on_save_charge(true) }} />
                    : ''}
                <Dimmer active={this.state.loading}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                <Form autoComplete="off" className="normal-form padd-r-0">
                    <Grid className="mar-t-5 padd-r-0">
                        <Grid.Column className="padd-r-0" mobile={16} tablet={16} computer={16}>

                            <Grid>
                                <Grid.Column tablet={8} computer={5}>
                                    <Form.Field className={this.state.charge.case_id <= 0 && this.state.form_submited ? 'requiredWithBgColor' : ''}>
                                        <label>Case <span className={this.state.charge.case_id <= 0 && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                        <SelectionSearch
                                            id="charge_case"
                                            name="charge_case"
                                            noOptionsMessage={'No Case Found !'}
                                            defaultValue={this.state.charge.case_id}
                                            isRequired={(this.state.charge.case_id <= 0 && this.state.form_submited)}
                                            options={this.state.cases || []}
                                            onChange={this.handle_change}
                                            autoFocus={true}
                                            openMenuOnFocus={true}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                {
                                    this.state.charge.case_id && this.state.charge.case_id > 0 ?
                                        <Grid.Column tablet={8} computer={5}>
                                            <Form.Field>
                                                <label>Onset Date</label>
                                                <div className="values">{this.convert_date(this.state.charge.injury_date) || ''}</div>                                      </Form.Field>
                                        </Grid.Column>
                                        : ''
                                }
                            </Grid>
                            {
                                this.state.charge.case_id && this.state.charge.case_id > 0 ?
                                    <Grid>

                                        <Grid.Column mobile={16} tablet={16} computer={16}>

                                            <Grid>
                                                <Grid.Column tablet={8} computer={5}>
                                                    <Form.Field>
                                                        <label>Primary Insurance</label>
                                                        <div className="values">{this.state.charge.primary_insurance_code || ''}</div>
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={5}>
                                                    <Form.Field>
                                                        <label>Secondary Insurance</label>
                                                        <div className="values">{this.state.charge.secondary_insurance_code || ''}</div>
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={6}>
                                                    <Form.Field>
                                                        <label>Tertiary Insurance</label>
                                                        <div className="values">{this.state.charge.tertiary_insurance_code || ''}</div>
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid>
                                            <Grid>
                                                <Grid.Column tablet={8} computer={5}>
                                                    <Form.Field className={this.state.charge_form_invalid.charge.location && this.state.form_submited ? "advance-quick-search case-reg-form requiredWithBgColor" : ' advance-quick-search case-reg-form'}>
                                                        <label>Location<span className={this.state.charge_form_invalid.charge.location && this.state.form_submited ? "req-alert" : 'req-alert_normal'}> (required)</span> </label>
                                                        <AdvancedControl
                                                            onGridRowSelection={this.on_location_grid_row_selection}
                                                            gridConfig={this.state.location_search_data}
                                                            controlId={global_constants.constants.advanced_control_type.location}
                                                            onSearch={this.on_location_search}
                                                            search_type={enum_type_of_search.place_of_service}
                                                            headerIdForGridTabNavigation={enum_type_of_search.place_of_service + 'grid_header_id'} />
                                                        <AutoSearchComponent on_blur={(e) => { this.on_blur_auto_search(e, enum_type_of_search.place_of_service) }}
                                                            control_id={"location"}
                                                            default_value={this.state.location_quick_search_format}
                                                            errorMessage={'No Location Found !'} prepareRenderList={this.render_suggestion_result} getList={this.get_location_quick_search_data_list}
                                                            prepareDataList={(data) => this.prepare_suggestion(data, enum_type_of_search.place_of_service)} selectresult={(item) => this.on_item_selection(item, enum_type_of_search.place_of_service)} is_focus={false}
                                                            show_clear_search={true} clear_search={() => this.clear_quick_search(enum_type_of_search.place_of_service)}
                                                            errorClass={this.state.charge_form_invalid.charge.location && this.state.form_submited ? 'req-background-inp' : ''}
                                                        />

                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={5}>
                                                    <Form.Field className={this.state.charge_form_invalid.charge.provider && this.state.form_submited ? "advance-quick-search case-reg-form requiredWithBgColor" : ' advance-quick-search case-reg-form'}>
                                                        <label>Billing Provider<span className={this.state.charge_form_invalid.charge.provider && this.state.form_submited ? "req-alert" : 'req-alert_normal'}> (required)</span></label>
                                                        <AdvancedControl
                                                            onGridRowSelection={this.on_provider_grid_row_selection}
                                                            gridConfig={this.state.provider_search_data}
                                                            controlId={global_constants.constants.advanced_control_type.provider}
                                                            onSearch={this.on_provider_search}
                                                            search_type={enum_type_of_search.provider}
                                                            headerIdForGridTabNavigation={enum_type_of_search.provider + 'grid_header_id'}
                                                        />
                                                        <AutoSearchComponent
                                                            on_blur={(e) => { this.on_blur_auto_search(e, enum_type_of_search.provider) }}
                                                            control_id={"billing_provider"}
                                                            default_value={this.state.provider_quick_search_format}
                                                            errorMessage={'No Billing Provider Found !'}
                                                            prepareRenderList={this.render_suggestion_result}
                                                            getList={this.get_provider_quick_search_data_list}
                                                            prepareDataList={(data) => this.prepare_suggestion(data, enum_type_of_search.provider)}
                                                            selectresult={(item) => this.on_item_selection(item, enum_type_of_search.provider)}
                                                            is_focus={false}

                                                            show_clear_search={true} clear_search={() => this.clear_quick_search(enum_type_of_search.provider)}
                                                            errorClass={this.state.charge_form_invalid.charge.provider && this.state.form_submited ? 'req-background-inp' : ''}
                                                        />

                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={6}>
                                                    <Form.Field className="advance-quick-search case-reg-form">
                                                        <label>Treating Provider</label>
                                                        <AdvancedControl
                                                            onGridRowSelection={this.on_treating_provider_grid_row_selection}
                                                            gridConfig={this.state.treating_provider_search_data}
                                                            controlId={global_constants.constants.advanced_control_type.treating_provider}
                                                            onSearch={this.on_treating_provider_search}
                                                            search_type={enum_type_of_search.treating_provider}
                                                            headerIdForGridTabNavigation={enum_type_of_search.treating_provider + 'grid_header_id'}
                                                        />
                                                        <AutoSearchComponent
                                                            on_blur={(e) => { this.on_blur_auto_search(e, enum_type_of_search.treating_provider) }}
                                                            control_id={"treating_provider"}
                                                            default_value={this.state.treating_provider_quick_search_format}
                                                            errorMessage={'No Treating Provider Found !'}
                                                            prepareRenderList={this.render_suggestion_result}
                                                            getList={this.get_treating_provider_quick_search_data_list}
                                                            prepareDataList={(data) => this.prepare_suggestion(data, enum_type_of_search.treating_provider)}
                                                            selectresult={(item) => this.on_item_selection(item, enum_type_of_search.treating_provider)}
                                                            is_focus={false}

                                                            show_clear_search={true} clear_search={() => this.clear_quick_search(enum_type_of_search.treating_provider)}
                                                        //errorClass={this.state.charge_form_invalid.charge.provider && this.state.form_submited ? 'search-error-thin' : ''}
                                                        />

                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid>

                                            <Grid>

                                                <Grid.Column tablet={8} computer={5}>
                                                    <Form.Field className={this.state.charge_form_invalid.charge.date_of_service && this.state.form_submited ? "requiredWithBgColor" : ''}>
                                                        <label>Date of Service <span className={this.state.charge_form_invalid.charge.date_of_service && this.state.form_submited ? "req-alert" : 'req-alert_normal'}> (required)</span></label>
                                                        <DatePicker id="charge_date_of_service" maxDate={moment().format('MM/DD/YYYY')} date_update={(e) => { this.handle_calendar_change(e, enum_date_type.date_of_service) }} date={this.state.charge.formatted_date_of_service} is_required={this.state.charge_form_invalid.charge.date_of_service && this.state.form_submited} />
                                                    </Form.Field>
                                                </Grid.Column>

                                                <Grid.Column tablet={8} computer={5}>
                                                    <Form.Field className={this.state.charge_form_invalid.charge.plan_of_care && this.state.form_submited ? "requiredWithBgColor" : ''}>
                                                        <label>Plan of Care Date</label>
                                                        <DatePicker id="charge_plan_of_care_date" date_update={(e) => { this.handle_calendar_change(e, enum_date_type.plan_of_care) }} date={this.state.charge.formatted_plan_of_care_date} is_required={this.state.charge_form_invalid.charge.plan_of_care && this.state.form_submited} />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={6}>
                                                    <Form.Field>
                                                        <label>Preauthorization</label>
                                                        <Selection
                                                            id="charge_preauthorization"
                                                            placeHolder='Select'
                                                            defaultValue={this.state.charge.pre_auth_id}
                                                            options={this.state.pre_auth_list}
                                                            onChange={(value, e) => this.charge_case_info_change({ name: "pre_auth", value: value }, e)}
                                                            hidden={true}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={16} computer={16}>
                                                    {
                                                        this.state.functional_reporting ?
                                                            <div className="common-forms-view">
                                                                <Grid>
                                                                    <Grid.Column>
                                                                        <p className="functional_reporting_10_visit_message"><i> {this.state.functional_reporting}</i></p>

                                                                    </Grid.Column>
                                                                </Grid>
                                                            </div>
                                                            : ''
                                                    }
                                                    <ChargeDetailComponent old_dx_list={this.state.old_dx_list}
                                                        charge_id={this.state.charge.charge_id}
                                                        selected_case_id={this.state.charge.case_id}
                                                        onRef={instance => { this.charge_detail_child = instance; }}
                                                        oncharge_detail={[]} charge={this.state.charge}
                                                        update_charge_details={this.state.update_charge_details}
                                                        calculate_charge_amount_params={this.state.calculate_charge_amount_params}
                                                        ff_sumaj_B30566_expected_rate_multiple_fee_schedules={this.props.ff_sumaj_B30566_expected_rate_multiple_fee_schedules} />

                                                </Grid.Column>


                                                <Grid.Column tablet={16} computer={16}>

                                                    <DiagnosisComponent page_state={enum_case_page_state.add} case_id={this.state.charge.case_id}
                                                        source_type={diagnosis_constant.dx_type.r5_case}
                                                        update_dx_List={this.update_dx_List}
                                                        show_sequence={true}
                                                        is_dragging={false}
                                                        onRef={instance => { this.diagnosis_child = instance; }} case_data={11} />
                                                </Grid.Column>
                                            </Grid>
                                        </Grid.Column>
                                    </Grid>
                                    : ''
                            }
                        </Grid.Column>
                    </Grid>


                </Form>

                <Modal
                    onClose={this.close_warning_pop_modal}
                    centered={false}
                    className=" default-modal"
                    open={this.state.show_warning_popup}
                    closeIcon={true}
                    id="warningPopup"
                    closeOnDimmerClick={false}
                >

                    <Modal.Header>
                        <h3>Confirm</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms response-message-poup" style={{ padding: "0" }}>
                                <ul >
                                    {
                                        this.state.charge_error_messages.map((_errorMessage, index) => {
                                            return (
                                                <li key={index}>{_errorMessage.val}</li>
                                            )
                                        })
                                    }

                                </ul>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                <Button id="btnCancleWarning" type="button" basic onClick={() => this.close_warning_pop_modal()}>
                                    Cancel
                                </Button>
                                <Button id="btnSaveWarning" type="button" primary onClick={() => this.save_warning_charges()}>
                                    OK
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                <Modal
                    onClose={this.close_replacement_warning_popup_modal}
                    centered={false}
                    className=" default-modal"
                    open={this.state.show_proc_replacement_warning_popup}
                    closeIcon={true}
                    id="ProcReplacementwarningPopup"
                    closeOnDimmerClick={false}
                >

                    <Modal.Header>
                        <h3>Confirm</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms response-message-poup" style={{ padding: "0" }}>
                                <ul >
                                    {
                                        this.state.charge_error_messages.map((_errorMessage, index) => {
                                            return (
                                                <li key={index}>{_errorMessage.val}</li>
                                            )
                                        })
                                    }

                                </ul>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">

                                <Button id="btnSaveWarning" type="button" primary onClick={() => this.proc_replacement_warning_popup_ok()}>
                                    OK
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                <Modal
                    onClose={this.close_kx_modifier_popup_modal}
                    centered={false}
                    className=" default-modal"
                    open={this.state.show_kx_modifier_popup1}
                    closeIcon={true}
                    id="KxModifierPopup1"
                    closeOnDimmerClick={false}
                >

                    <Modal.Header>
                        <h3>Confirm</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms" style={{ padding: "0" }}>
                                <p>{charge_messages.kx_modifier_warning2}</p>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                <Button id="btnKxModifierCancelWarning" type="button" basic onClick={() => this.close_kx_modifier_popup_modal()}>
                                    No
                                </Button>
                                <Button id="btnKxModifierWarning" type="button" primary onClick={() => this.save_kx_modifier_warning()}>
                                    Yes
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                <Modal
                    onClose={this.close_kx_modifier_popup_modal}
                    centered={false}
                    className=" default-modal"
                    open={this.state.show_kx_modifier_popup}
                    closeIcon={true}
                    id="KxModifierPopup"
                    closeOnDimmerClick={false}
                >

                    <Modal.Header>
                        <h3>Confirm</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms" style={{ padding: "0" }}>
                                <p>{charge_messages.kx_modifier_warning1}</p>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                <Button id="btnKxModifierCancelWarning" type="button" basic onClick={() => this.close_kx_modifier_popup_modal()}>
                                    No
                                </Button>
                                <Button id="btnKxModifierWarning" type="button" primary onClick={() => this.save_kx_modifier_warning()}>
                                    Yes
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                <Modal
                    onClose={this.close_cp_insurance_check_popup_modal}
                    centered={false}
                    className=" default-modal"
                    open={this.state.show_cp_insurance_check}
                    closeIcon={true}
                    id="CPInsuranceCheck"
                    closeOnDimmerClick={false}
                >

                    <Modal.Header>
                        <h3>Confirm</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms" style={{ padding: "0" }}>
                                <p>{charge_messages.cp_insurance_check}</p>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                <Button id="btnCPInsuranceCheckNo" type="button" basic onClick={() => this.close_cp_insurance_check_popup_modal()}>
                                    No
                                </Button>
                                <Button id="btnCPInsuranceCheckYes" type="button" primary onClick={() => this.save_cp_insurance_check()}>
                                    Yes
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                <Modal
                    onClose={this.close_has_dx_7th_digit_popup_modal}
                    centered={false}
                    className=" default-modal"
                    open={this.state.show_has_dx_7th_digit_popup}
                    closeIcon={true}
                    id="hasDx7thDigitAPopup"
                    closeOnDimmerClick={false}
                >

                    <Modal.Header>
                        <h3>Confirm</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms" style={{ padding: "0" }}>
                                <p>{this.state.dx_7th_digit_message}</p>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                <Button id="btnHasDx7thDigitACancelWarning" type="button" basic onClick={() => this.close_has_dx_7th_digit_popup_modal()}>
                                    Cancel
                                </Button>
                                <Button id="btnHasDx7thDigitAWarning" type="button" primary onClick={() => this.save_has_dx_7th_digit_popup()}>
                                    OK
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                <Modal
                    onClose={this.close_missing_functional_reporting_modal}
                    centered={false}
                    className=" default-modal"
                    open={this.state.show_missing_functional_reporting_popup}
                    closeIcon={true}
                    id="missingFunctionalReportingPopup"
                    closeOnDimmerClick={false}
                >

                    <Modal.Header>
                        <h3>Confirm</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms" style={{ padding: "0" }}>
                                <p>{this.state.missing_functional_reporting_code_message}</p>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                <Button id="btnMissingCodeCancelWarning" type="button" basic onClick={() => this.close_missing_functional_reporting_modal()}>
                                    No
                                </Button>
                                <Button id="btnMissingCodeWarning" type="button" primary onClick={() => this.save_missing_functional_reporting_modal()}>
                                    Yes
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                <Modal
                    onClose={this.close_functional_reporting_error_modal}
                    centered={false}
                    className=" default-modal"
                    open={this.state.show_functional_reporting_error_popup}
                    closeIcon={true}
                    id="functionalReportingErrorPopup"
                    closeOnDimmerClick={false}
                >

                    <Modal.Header>
                        <h3>Confirm</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms" style={{ padding: "0" }}>
                                <p>{this.state.functional_reporting_error_message}</p>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                <Button id="btnFunctionalReportingErrorNo" type="button" basic onClick={() => this.close_functional_reporting_error_modal()}>
                                    No
                                </Button>
                                <Button id="btnFunctionalReportingErrorYes" type="button" primary onClick={() => this.save_functional_reporting_error_modal()}>
                                    Yes
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                <Modal
                    onClose={this.close_invalid_case_error_modal}
                    centered={false}
                    className=" default-modal"
                    open={this.state.show_invalid_case_error_popup}
                    closeIcon={true}
                    id="invalidCaseErrorPopup"
                    closeOnDimmerClick={false}
                >

                    <Modal.Header>
                        <h3>Confirm</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms" style={{ padding: "0" }}>
                                <p>{this.state.invalid_case_messge}</p>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                <Button id="btnInvalidCaseErrorOK" type="button" primary onClick={() => this.save_invalid_case_error_modal()}>
                                    Yes
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                <Modal
                    onClose={this.close_proc_replacement_modal}
                    centered={false}
                    className=" default-modal"
                    open={this.state.show_proc_replacement_popup}
                    closeIcon={true}
                    id="procReplacementPopup"
                    closeOnDimmerClick={false}
                >

                    <Modal.Header>
                        <h3>Confirm</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms" style={{ padding: "0" }}>
                                <p> Below code is/are not valid for this payer. Please select from one of the valid codes for their replacement.</p>
                            </div>
                            <div className="common-forms" style={{ padding: "0" }}>
                                <div className="mar-t-15 customtable">
                                    <div className="table-responsive">
                                        <Table id="ProcReplacement_table">
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>CPT Code</Table.HeaderCell>
                                                    <Table.HeaderCell>Valid Code</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            {this.state.proc_replacement_list.length > 0 && (
                                                <Table.Body>
                                                    {
                                                        this.state.proc_replacement_list.map((item: any, index: number) => {
                                                            item.selected_proc_code_id = item.selected_proc_code_id ? item.selected_proc_code_id :
                                                                (item.valid_proc_codes.length > 0 ? item.valid_proc_codes[0].procedure_code_id : '');
                                                            return (
                                                                <Table.Row key={index}>
                                                                    <Table.Cell>
                                                                        {item.invalid_proc_code}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Selection
                                                                            id={'ddlProcReplacement_' + index}
                                                                            defaultValue={item.selected_proc_code_id}
                                                                            options={this.update_data(item.valid_proc_codes)}
                                                                            onChange={value => { this.invalid_proc_replacement_change(value, item, index) }}
                                                                        />

                                                                    </Table.Cell>

                                                                </Table.Row>
                                                            )
                                                        })
                                                    }
                                                </Table.Body>
                                            )}

                                        </Table>
                                    </div>
                                </div>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column text-right padd-r-0" style={{ marginTop: '30px' }}>
                                <Button id="btnprocReplacementNo" type="button" basic onClick={() => this.close_proc_replacement_modal()}>
                                    Cancel
                                </Button>
                                <Button className="margin-zero" id="btnprocReplacementYes" type="button" primary onClick={() => this.save_proc_replacement_modal()}>
                                    OK
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
                <ChargePaymentFullModalComponent
                    payment_full_submitted={this.state.payment_full_submitted}
                    payment_state={this.state.payment}
                    close_payment_full_moda={this.close_payment_full_moda}
                    save_payment_full_moda={this.save_payment_full_moda}
                    payment_loading={this.state.payment_loading}
                    payment_code_list={this.state.payment_code_list}
                    show_payment_full_modal={this.state.show_payment_full_modal}
                />

                {this.state.show_multiple_procode_for_same_revenue_popup ?
                    <ConfirmationComponent message={this.state.multiple_procode_for_same_revenue_message}
                        title='Confirm' show_modal={this.state.show_multiple_procode_for_same_revenue_popup}
                        onCloseModal={this.on_close_multiple_procode_for_same_revenue_popup}
                        save_button_text='Confirm'
                        on_force_save_click={(e) => { this.save_multiple_procode_for_same_revenue_modal() }} />
                    : ''}


                <Modal
                    onClose={this.on_close_multiple_procode_for_same_revenue_confirm}
                    centered={false}
                    className=" default-modal"
                    open={this.state.show_multiple_procode_for_same_revenue_confirm_popup}
                    closeIcon={true}
                    id="multi_proc_revenue"
                    closeOnDimmerClick={false}
                >

                    <Modal.Header>
                        <h3>Confirm</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms" style={{ padding: "0" }}>
                                <p>{charge_messages.show_multiple_procode_for_same_revenue_confirm_messge}</p>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                <Button id="btn_multi_proc_revenue_ok" type="button" primary onClick={() => this.on_close_multiple_procode_for_same_revenue_confirm()}>
                                    OK
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                {this.state.show_bummepd_charge_message_popup ?
                    <ConfirmationComponent message={this.state.charge_popup_message}
                        title={this.state.charge_popup_title} show_modal={this.state.show_bummepd_charge_message_popup}
                        onCloseModal={this.on_close_bummepd_charge_popup}
                        save_button_text={this.state.charge_popup_ok_btn_text} showCancelButton={this.state.charge_popup_show_cancel}
                        on_force_save_click={(e) => { this.state.charge_popup_action() }} />
                    : ''}
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        //get_patient_search: get_patient_search
        update_patient_header_info: update_patient_header_info,
        set_active_patient: set_active_patient
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        user_details: state.user_login_details,
        patient_details: state.patient_details,
        ff_sumaj_B30566_expected_rate_multiple_fee_schedules: get_lauch_darkley_key_value(state.launch_darkly, ExpectedRateMultipleFeeSchedule.sumajB30566ExpectedRateMultipleFeeSchedules),
        magnusPlat604AuditLogCreateTheAddUpdateDeleteControllersForPaymentActions: get_lauch_darkley_key_value(state.launch_darkly, adminBillingAuditLogFeatures.magnusPlat604AuditLogCreateTheAddUpdateDeleteControllersForPaymentActions)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddChargeComponent));